import React from 'react';
import {Fab, makeStyles} from '@material-ui/core';
import {Add} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    root: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}));
export default  function PlusFab(props){
    const classes = useStyles();
    return (
        <Fab color="primary" className={classes.root} aria-label="add" {...props}>
            <Add />
        </Fab>
    );
}
