import React from 'react';
import {makeStyles,TablePagination} from "@material-ui/core";
import {ReactComponent as EmptySvg} from "../assets/img/empty.svg";
import Strings from "../assets/strings";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        left: "0",
        position: "sticky",
        bottom: "0",
        background: "#eeeeee",
        borderRadius: "4px",
        // [theme.breakpoints.down('xs')]: {
        //     margin: "0",
        //     marginLeft: 10,
        // },
        "& .MuiTablePagination-selectRoot":{
            margin: "0 10px",
            border: "none",
            background: "transparent"
        },
        "& .MuiToolbar-root":{
            justifyContent: "center",
            padding: "0",
            minHeight: "37px",
            height: "37px",
            overflow: "hidden"
        },
        "& .MuiTablePagination-spacer":{
            display: "none",
        }
    },
    emptyCont:{
        paddingTop:"7%",
        textAlign:"center",
        opacity: .7,
        "&:hover":{
            opacity: 1,
        },
        "& p":{
            marginTop:0,
            color: theme.palette.text.hint,
        }
    }
}));
export default function Pagination({empty,pageCount,...props}){
    const classes = useStyles();
    if(empty){
        return   (
            <div className={classes.emptyCont}>
                <EmptySvg width={140} height={140}/>
                <p>{Strings.aunNoTienes} {Strings.registros}!</p>
            </div>
        )
    }
    if((props.count / props.rowsPerPage) <= 1) return <></>;

    return (
        <TablePagination
            className={classes.root}
            {...props}
            labelRowsPerPage=""
            rowsPerPageOptions={[5, 15, 25,50,100]}
            component="div"
            SelectProps={{
                name:"pageSize",
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
            }}
        />
    );
}
