import React from 'react';
import {withStyles, Typography, Box} from '@material-ui/core';

import Image from 'material-ui-image'
import DefaultImgEmp from '../assets/img/defaultAvatar_empresa.jpg';
import NoImg from '../assets/img/defaultAvatar.jpg';


const styles = theme => ({
    root: {
        cursor:"pointer",
        border: "1px solid #ddd",
        background: "#fff",
        textAlign: "center",
        padding: 20,
        position: "relative",
        [theme.breakpoints.down('xs')]: {
            padding: "10px 15px",
            textAlign: "left",
            border: "none",
            borderBottom: "1px solid #f1f1f1"
        },
        "& .moreVertIcon":{
            position: "absolute",
            top: 5,
            right: 5,
            width: 27,
            height: 27,
        }
    },
    imgCont:{
        width: 100,
        height: 100,
        marginBottom:8,
        margin: "0 auto",
        position: "relative",
        [theme.breakpoints.down('xs')]: {
            float:"left",
            width:50,
            height:50,
            marginRight: 12
        },
    },
    imgCircle: {
        borderRadius: "50%",
        width:"100%",
        height:"100%",

    },
    status:{
        display: "block",
        width: 12,
        height: 12,
        background: "gray",
        border: "3px solid #fff",
        borderRadius: "50%",
        position: "absolute",
        right: 5,
        bottom: 5,
        "&.e0":{
            background: "red"
        },
        "&.e1":{
            background: "#01a855"
        },
        "&.e2":{
            background: "orange"
        }
    }
  });

class ClientCard extends React.Component {


    render(){
        const { cliente,classes,onClick } = this.props;
        return (
            <div className={classes.root} onClick={()=>onClick(cliente.cliId)}>
                <div className={classes.imgCont} >
                    <Image alt="avatar" src={ cliente.cliAvatar?cliente.cliAvatar: (cliente.cliTipo === 2 ?DefaultImgEmp: NoImg)}
                           className={classes.imgCircle} animationDuration={1000}/>
                    <span className={classes.status +" e"+ cliente.cliEstado} ></span>
                </div>
                <div>
                    <Typography variant="subtitle1" noWrap={true}>
                        {cliente.cliNombres +" "+cliente.cliApellidos}
                    </Typography>
                    {/*<Box display={"flex"} justifyContent="space-around" fontSize=".8rem">*/}
                    {/*    <Box>{Strings.capital}: {formatPeso(cliente.capitalPendiente)}</Box>*/}
                    {/*    <Box>{Strings.balance}: {formatPeso(cliente.balancePendiente)}</Box>*/}
                    {/*</Box>*/}
                </div>
            </div>

        )
    }
}
export default withStyles(styles)(ClientCard);