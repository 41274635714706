import {themeTypes } from './types';

export function toggleDrawer() {
    return (dispatch, getState) => {
        if (getState().theme.drawerOpen){
            dispatch({ type: themeTypes.CLOSE_DRAWER})
        }else{
            dispatch({ type: themeTypes.OPEN_DRAWER})
        }
    }
}
export function togglePaypalPlan() {
    return (dispatch, getState) => {
        if (getState().theme.paypalPlanOpen){
            dispatch({ type: themeTypes.CLOSE_PAYPAL_PLAN})
        }else{
            dispatch({ type: themeTypes.OPEN_PAYPAL_PLAN})
        }
    }
}
export function toggleViewTable() {
    return (dispatch) => {
        dispatch({ type: themeTypes.TOGGLE_VIEW_TABLE})
    }
}
export function setInstallBannerEvent(e) {
    return (dispatch) => {
        dispatch({ type: themeTypes.SET_INSTALL_BANNER_EVENT,payload:e})
    }
}