import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {IconButton, Toolbar, Typography} from '@material-ui/core';
import {ArrowBack, Close} from '@material-ui/icons';
import {AppBar,Hidden} from '@material-ui/core';
const closeBtnStyle = {
      order: 1,
      background: "rgba(0, 0, 0, 0.05)",
      borderRadius: 0,
      marginLeft: "0px !important",
}
const useStyles = makeStyles(theme => ({
  root:{
    "& .MuiIconButton-sizeSmall:not(:first-child)":{
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      height: "40px !important",
      "& >div":{
        height: "40px !important",
        minHeight: "auto",
        padding: "0 "+theme.spacing(1)+"px",
      }
    },
  },
  closeBtn:{
     ...closeBtnStyle
  },
  closeBtn_upSm:{
    [theme.breakpoints.up('sm')]: {
      ...closeBtnStyle
    }
  },
  title: {
    flex: 1,
    fontSize:"1.2rem",
    fontWeight: 400,
    marginLeft: theme.spacing(1),
  }
}));
export default function TopBarDialog({nofullScreen,...props}){
    const classes = useStyles();
    const {title,onClose} = props;
    return (
      <AppBar position="static" elevation={0} className={"d-print-none "+classes.root}>
        <Toolbar style={nofullScreen ? {minHeight: 40,padding: "0 5px"}:{}}>
          <IconButton size={"small"} edge="start" color="inherit" onClick={onClose} className={nofullScreen ? classes.closeBtn :classes.closeBtn_upSm}>
            {nofullScreen ?(
                <Close/>
            ):(
              <>
                <Hidden xsDown><Close/></Hidden>
                <Hidden smUp><ArrowBack/></Hidden>
              </>
            )}
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          {props.children}
        </Toolbar>
      </AppBar>
    );
}