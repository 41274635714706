import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import { getPermisos} from '../../services';
import { Grid, Dialog, DialogContent, DialogActions, Button,Checkbox,Accordion,AccordionSummary,AccordionDetails ,
    FormControlLabel, TextField, useMediaQuery,makeStyles} from '@material-ui/core';
import Strings from '../../assets/strings';

import {agregarEditarRol} from "../../redux/actions/rolesActions";
import {MostrarError} from "../../components/MostrarError";
import TopBarDialog from "../../components/topBar/TopBarDialog";
import {useHistory, useLocation} from "react-router-dom";
import {nullToEmptyString} from "../../helpers";
import {SlideLeft} from "../../components/transitions";
import StyledCheckbox from "../../components/StyledCheckbox";
import LoadingProgress from "../../components/LoadingProgress";
import {ExpandMore} from '@material-ui/icons';
const useStyles = makeStyles((theme) => ({
    cbRow:{
        borderBottom: "1px solid" +theme.palette.grey[200],
        "& label":{
                width: "100%",
        },
    },
    Accordion: {
        margin: "0 !important",
        boxShadow: "none",
        borderBottom: "1px solid" +theme.palette.grey[200],
    },
    AccordionDetails :{
        padding: 0,
        paddingBottom: 8,
    },
    AccordionSummaryRoot: {
        minHeight:"0 !important",
        padding: 0,
    },

    AccordionSummaryContent: {
            margin: "0 !important",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));
const initialState = {
        rolId: "",
        rolDescripcion: "",
        permisos: [],
};
function Crear(props) {
    const classes = useStyles();
    const {goBack} = useHistory();
    const {state:urlState={}} = useLocation();

    const smVewport = useMediaQuery((theme)=>theme.breakpoints.down('sm'));
    const {isPending,error} = props;
    const [state, setState] = useState(initialState);
    const [permisos, setPermisos] = useState([]);


    useEffect(() => {
        getPermisos()
            .then(({data}) => {
                setPermisos(data);
            })
    }, []);
    useEffect(() => {
        if(!!urlState && urlState.rolId) {
            setState(state => ({...state, ...nullToEmptyString(urlState)}));
        }else {
            setState(initialState);
        }
    }, [setState, urlState]);
    const includePermiso = (e, arrayPer=[],parent)=> {
        const {name, checked } = e.target;
         const perId  = parseInt(name);
        let permisosSelected = new Set([...state.permisos]);
        if(checked){
            permisosSelected = new Set([...permisosSelected, perId,...arrayPer]);
            if(parent)
                permisosSelected = new Set([...permisosSelected,parent]);

        } else{
            permisosSelected.delete(perId)
            if(arrayPer)
                arrayPer.forEach(item => permisosSelected.delete(item))
        }
         setState({ ...state, permisos:[...permisosSelected] });
    };
    const handleSubmit = e => {
        e.preventDefault();
        props.agregarEditarRol(state);
    };
    return (
        <>
            <Dialog open={Boolean(urlState && urlState.hasOwnProperty("rolId"))} onClose={goBack} maxWidth={"sm"}
                    {...(smVewport ? {TransitionComponent: SlideLeft}:{})}
                    fullScreen={smVewport} fullWidth>

                <TopBarDialog title={!!state.rolId ? Strings.actualizar : Strings.agregar+" "+Strings.nuevo} onClose={goBack}/>
                <DialogContent>
                    {error && <MostrarError errors={error}/>}
                    {isPending && <LoadingProgress />}
                    <br/>
                    <form onSubmit={handleSubmit} id="form1">
                        <Grid container spacing={1}>
                            <Grid  item xs={12}>
                                <TextField
                                    label={Strings.descripcion}
                                    value={state.rolDescripcion}
                                    onChange={(e)=>setState({ ...state, "rolDescripcion": e.target.value })}
                                    inputProps={{maxLength: 50}}
                                    required
                                    fullWidth
                                />
                            </Grid>
                            <Grid  item xs={12}>
                                {!!permisos.length && permisos.map(per =>
                                    (per.acciones && per.acciones.length > 0) ? (
                                        <Accordion key={per.permisoId} className={classes.Accordion}>
                                            <AccordionSummary expandIcon={<ExpandMore/>}
                                                                   classes={{
                                                                       root:classes.AccordionSummaryRoot,
                                                                       content:classes.AccordionSummaryContent}}>
                                                <FormControlLabel
                                                    checked={state.permisos.indexOf(per.permisoId) !== -1}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    onChange={(e)=>includePermiso(e,per.acciones.map(acc=>acc.key))}
                                                    control={<Checkbox  name={per.permisoId?.toString()}/>}
                                                    label={per.permiso}
                                                />
                                            </AccordionSummary>
                                            <AccordionDetails  className={classes.AccordionDetails }>
                                                <Grid container>
                                                    {per.acciones.map((acc)=>
                                                        <Grid item  xs={6} md={4} key={acc.key}>
                                                            <FormControlLabel
                                                                control={
                                                                    <StyledCheckbox
                                                                        checked={state.permisos.indexOf(acc.key) !== -1}
                                                                       // checked={isResSelected(subPer.permisoId + '_' + acc.key)}
                                                                        onChange={(e)=>includePermiso(e,[],per.permisoId)}
                                                                        color="primary"
                                                                        inputProps={{
                                                                            'aria-label': 'primary checkbox',
                                                                            name: acc.key?.toString(), //+ '_' + acc.key,
                                                                        }}
                                                                    />
                                                                }
                                                                label={acc.value}
                                                            />
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </AccordionDetails >
                                        </Accordion>
                                        ):(
                                            <div className={classes.cbRow} key={per.permisoId} >
                                                <FormControlLabel
                                                    checked={state.permisos.indexOf(per.permisoId) !== -1}
                                                    onChange={(e)=>includePermiso(e,per.acciones.map(acc=>acc.key))}
                                                    control={<Checkbox  name={per.permisoId?.toString()}/>}
                                                    label={per.permiso}
                                                />
                                            </div>
                                        )
                                )}
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={goBack} color="primary">{Strings.cancelar}</Button>
                    <Button type="submit" form={"form1"} color="primary" disabled={isPending || state.registroDelSistema}>
                        {!!state.rolId ? Strings.actualizar : Strings.guardar}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
const mapStateToProps = (state) => ({
    error: state.roles.error,
    isPending: state.roles.isPending,
});
export default connect(mapStateToProps, { agregarEditarRol })(Crear);
