import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';


const cleckedIcon = "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 21 21' %3E%3Cpath d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z' fill='%23F44336'/%3E%3C/svg%3E\")";
const closeIcon = "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 21 21' %3E%3Cpath d='M9 16.17L4.83 12l-1.42 1.41L9 19 20 4l-1.41-1.41z' fill='%232a8fcd'/%3E%3C/svg%3E\")";

// Inspired by blueprintjs
export default function StyledCheckbox({xIsCheck, ...props}) {
    const useStyles = makeStyles({
        root: {
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        icon: {
            width: 20,
            height: 20,
            padding: "0px 4px 1px 1px",
            '$root.Mui-focusVisible &': {
                outline: '2px auto rgba(19,124,189,.6)',
                outlineOffset: 2,
            },
            'input:disabled ~ &': {
                boxShadow: 'none',
                background: 'rgba(206,217,224,.5)',
            },
            'input:hover ~ &': {
                backgroundColor: '#f5f8fa',
            },
            '&:before': {
                display: 'block',
                width: 20,
                height: 20,
                backgroundImage:xIsCheck?closeIcon:cleckedIcon,
                content: '""',
            },
        },
        checkedIcon: {
            '&:before': {
                display: 'block',
                width: 20,
                height: 20,
                backgroundImage:xIsCheck?cleckedIcon:closeIcon,
                content: '""',
            }

        },
    });

    const classes = useStyles();

    return (
        <Checkbox
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            inputProps={{ 'aria-label': 'decorative checkbox' }}
            {...props}
        />
    );
}

