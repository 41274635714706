import LocalizedStrings from 'react-localization';
import en from "./en.json";
import es from "./es.json";
const lang = {
    es:"Español",
    en:"English",
}

const index = new LocalizedStrings({
    es:{...lang,...es},
    en:{...lang,...en},
});

export default index;