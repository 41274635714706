import {contratoTypes, _PENDING, _REJECTED, _FULFILLED } from '../actions/types';
 
const initialState = {
    list: {},
    filtered:{},
    byId: {},
    isFetching: false,
    isPending: false,
    error: {},
}
const index = (state = initialState, action = {}) => {
    switch (action.type) {
        case contratoTypes.MOSTRAR_CONTRATOS + _PENDING: {
            return {
                ...state,
                isFetching: true,
                error: {}
            }
        }
        case contratoTypes.MOSTRAR_CONTRATOS + _FULFILLED: {
            return {
                ...state,
                list: action.payload.data,
                filtered: action.payload?.config?.params ?? {},
                isFetching: false,
                byId:{},
                error: {} 
            }
        }
        case contratoTypes.MOSTRAR_CONTRATOS + _REJECTED: {
            const { data, status } = action.payload.response || { data:"Error no definido"};
            return {
                ...state,
                isPending: false,
                isFetching: false,
                error: { data, status }
            }
        }
        //mostrar por id
        case contratoTypes.MOSTRAR_CONTRATO_POR_ID + _PENDING: {
            return {
                ...state,
                isFetching: true,
                error: {}
            }
        }
        case contratoTypes.MOSTRAR_CONTRATO_POR_ID + _FULFILLED: {
            return {
                ...state,
                byId: action.payload.data,
                isFetching: false,
                error: {}
            }
        }
        case contratoTypes.MOSTRAR_CONTRATO_POR_ID + _REJECTED: {
            const { data, status } = action.payload.response || { data:"Error no definido"};
            return {
                ...state,
                isPending: false,
                isFetching: false,
                error: { data, status }
            }
        }
        //agregar  o editar
        case contratoTypes.AGREGAR_EDITAR_CONTRATO + _PENDING: {
            return {
                ...state,
                isPending: true,
                error: {}
            }
        }
        case contratoTypes.AGREGAR_EDITAR_CONTRATO + _FULFILLED: {
            const { data } = action.payload;
            let newData = {...state.byId, ...data, new: true}

            let list = state.list;
            list.results?
                list.results = list.results.filter(e => e.conId !== data.conId): list.results = [];
                //var list = state.list.filter(e => e.carId !== data.carId)
            list.results.unshift(data)
            return {
                ...state,
              //  list,
                isPending: false,
                byId: newData,
                error: {}
            }
        }
        case contratoTypes.AGREGAR_EDITAR_CONTRATO + _REJECTED: {
            const { data, status } = action.payload.response || { data:"Error no definido"};
            return {
                ...state,
                isPending: false,
                isFetching: false,
                error: { data, status }
            }
        }
        //Eliminar

        case contratoTypes.ELIMINAR_CONTRATO + _FULFILLED: {
            const { data: id } = action.payload;
            let list = state.list
            list.results = list.results.filter(e => e.conId !== id)
            --list.totalRecordCount
            return {
                ...state,
                list,
                isPending: false,
                byId:{},
                error: {}
            }
        }
        case contratoTypes.TOGGLE_MODAL_CONTRATO: {
            return {
                ...state,
                error: {}
            }
        }
        case contratoTypes.TOGGLE_MODAL_EDIT_CONTRATO: {
            return {
                ...state,
                error: {}
            }
        }
        //agregar  nota
        case contratoTypes.AGREGAR_CON_NOTA + _PENDING: {
            return {
                ...state,
                isPending: true,
                error: {}
            }
        }
        case contratoTypes.AGREGAR_CON_NOTA + _FULFILLED: {
            const { data } = action.payload;
            const notas = state.byId.notas || [];
            notas.push(data);
            return {
                ...state,
                byId: {...state.byId, notas},
                isPending: false,
            }
        }
        case contratoTypes.AGREGAR_CON_NOTA + _REJECTED: {
            return {
                ...state,
                isPending: false,
            }
        }
        //eliminar nota
        case contratoTypes.ELIMINAR_CON_NOTA + _PENDING: {
            return {
                ...state,
                isPending: true,
                error: {}
            }
        }
        case contratoTypes.ELIMINAR_CON_NOTA + _FULFILLED: {
            const { data } = action.payload;
            const notas = state.byId.notas.filter(n=>n.ctnId !== data)
            return {
                ...state,
                byId: {...state.byId, notas},
                isPending: false,
            }
        }
        case contratoTypes.ELIMINAR_CON_NOTA + _REJECTED: {
            return {
                ...state,
                isPending: false,
                isFetching: false,
            }
        }
        default:
            return state;
    }
};

export default index;
