import {dashBoardTypes} from './types';
import {getWidgets, getIngresos, getCxC} from '../../services';

export function mostrarDashBoardData() {
    return dispatch => {
        dispatch({
            type: dashBoardTypes.MOSTRAR_DASHBOARD_DATA,
            payload: Promise.all([getWidgets(),getIngresos(),getCxC()])
        });
    }
}
export function mostrarCxC(filter) {
    return dispatch => {
        dispatch({
            type: dashBoardTypes.MOSTRAR_CxC,
            payload: getCxC(filter)
        })
    }
}
// export function mostrarGetNotificaciones() {
//     return dispatch => {
//         dispatch({
//             type: dashBoardTypes.MOSTRAR_NOTIFICACIONES,
//             payload: getNotificaciones()
//         })
//     }
// }

// export function mostrarIngresosGrafico(year) {
//     return dispatch => {
//         dispatch({
//             type: dashBoardTypes.MOSTRAR_DASHBOARD_DATA,,
//             payload: getIngresos(year)
//         }).catch(({response})=>{
//             dispatch(Notifications.error(errorToNotifObj(response)));
//         })
//     }
// }