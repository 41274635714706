import React, {useEffect, useState} from 'react';
import { GoogleMap,Autocomplete,Marker, } from '@react-google-maps/api';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent, FormControl,
    IconButton,
    Input,
    InputAdornment,
    InputLabel, TextField, useMediaQuery,
} from "@material-ui/core";
import TopBarDialog from "./topBar/TopBarDialog";
import {MostrarError} from "./MostrarError";
import Strings from "../assets/strings";
import {RoomOutlined} from "@material-ui/icons";
import {getLSLang} from "../helpers";
import {HtmlTooltip} from "./index";
const containerStyle = {
    width: '100%',
    minHeight: 400,
    height: '100%'
};
const defaultCenter = {
    lat: 0,
    lng: 0
};

const inputStyle = {
    boxSizing: "border-box",
    border: "1px solid transparent",
    width: "calc(100% - 20px)",
    height: "40px",
    padding: "0px 12px",
    boxShadow: "rgba(0, 0, 0, 0.3) 0px 2px 6px",
    fontSize: "14px",
    outline: "none",
    position: "absolute",
    top: "10px",
    left: "10px"
}
function SearchBox({handleChange,direccion,coordenadas}) {
    const smVewport = useMediaQuery(theme=>theme.breakpoints.down('sm'));

    const [autocomplete , setAutocomplete ] = React.useState(null);
    const [autocompleteIn, setAutocompleteIn ] = React.useState(null);
    const [openMap, setOpenMap] = useState(false);

    const [error, setError] = React.useState(null);
    const [latLng, setLatLng] = React.useState(defaultCenter);

    useEffect(()=>{
        if(coordenadas){
            let latlng = coordenadas.split(',');
            setLatLng({lat:parseFloat(latlng[0]), lng:parseFloat(latlng[1])});
        }
    },[coordenadas])
    const  handledLatLng = async (obj)=>{
        let lat = obj.latLng.lat();
        let lng = obj.latLng.lng();

        let toAdd = {
            coordenadas: lat+","+lng
        };
        if(obj.formatted_address){
            toAdd.direccion = obj.formatted_address;
            handleChange(toAdd);

        } else{
            coordenateToName(obj.latLng).then(res=>{
                toAdd.direccion = res;
                handleChange(toAdd);
            });
        }

    }
    const onPlaceChanged = (inModal) =>{
        let input = inModal ? autocompleteIn : autocomplete;

        if (input !== null) {
             let place = input.getPlace();
             const {formatted_address,geometry } = place;

             handledLatLng({latLng:geometry.location, formatted_address},true);
        }

    }
    const currentLocation = ()=>{
        navigator.geolocation.getCurrentPosition((pos)=>{
            const crd = pos.coords;

            let latlng = new window.google.maps.LatLng(crd.latitude, crd.longitude);

            handledLatLng({'latLng': latlng});

        }, (err)=>{
            setError(Strings.ubicacionDenegada);
        }, {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
        });
    }
    const handleInputChange = (e)=>{
        const {value} = e.target
        if(!value){
           return  handleChange({coordenadas: "",direccion: ""});
        }
        handleChange({direccion: value,coordenadas});
    }

    const country = getLSLang("c");
    return window?.google?.maps ? <>
        <Autocomplete
            onLoad={(v)=>setAutocomplete(v)}
            onPlaceChanged={onPlaceChanged}
            {...(country && {restrictions:{country}})}
        >
            <FormControl fullWidth>
                <InputLabel htmlFor="direccion">{Strings.direccion}</InputLabel>
                <Input
                    id="direccion"
                    value={direccion}
                    onChange={handleInputChange}
                    autoComplete={"new-password"}
                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                color={"primary"}
                                onClick={()=>setOpenMap(true)}
                            >
                                <RoomOutlined/>
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
        </Autocomplete>

        <Dialog open={openMap}  onClose={()=>setOpenMap(false)} fullScreen={smVewport} fullWidth maxWidth={"md"} >
            <TopBarDialog title={Strings.direccion} onClose={()=>setOpenMap(false)} nofullScreen/>
            <DialogContent style={{ padding: 0}} >
                <MostrarError errors={error} onClose={()=>setError(null)}/>

                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={latLng}
                    zoom={15}
                    disableDefaultUI={true}
                    options={{
                        disableDefaultUI:true
                    }}
                >
                    <Autocomplete
                        onLoad={(v)=>setAutocompleteIn(v)}
                        onPlaceChanged={()=>onPlaceChanged(true)}
                        {...(country && {restrictions:{country}})}
                    >
                        <input
                            type='text'
                            value={direccion}
                            onChange={handleInputChange}
                            placeholder={Strings.buscar}
                            style={inputStyle}
                        />
                    </Autocomplete>
                    <Marker
                        draggable
                        position={latLng}
                        //   icon = "https://drive.google.com/uc?id=0B3RD6FDNxXbdVXRhZHFnV2xaS1E"
                        onDragEnd={handledLatLng}

                        // onRightClick={() => props.onMarkerRightClick(marker)}
                    />
                </GoogleMap>
            </DialogContent>
            <DialogActions>
                <HtmlTooltip title={Strings.ubicacionActualMjs}>
                    <Button color="primary" onClick={currentLocation}>{Strings.ubicacionActual}</Button>
                </HtmlTooltip>
                <Button color="primary" onClick={()=>setOpenMap(false)}>Ok</Button>
            </DialogActions>
        </Dialog>
    </>:<>
        <TextField
            value={direccion}
            onChange={handleInputChange}
            label={Strings.direccion}
            fullWidth
        />
    </>
}
export default React.memo(SearchBox)

const coordenateToName = latLng => {
    return new Promise((resolve, reject) => {
        if(typeof(window.google) !== "object") reject("no map window")

        let geocoder = new window.google.maps.Geocoder();

        geocoder?.geocode( { latLng }, function ( result, status ) {
            if (status === 'OK') {
                resolve(result[0].formatted_address);
            } else {
                reject(status);
            }
        });
    });
};

