import { inmCaractTypes } from './types';
import { getInmCaract, addInmCaract, editInmCaract, delInmCaract } from '../../services';
import _ from 'lodash';
import history from "../../helpers/history"

export function mostrarCaracteristicas() {
    return dispatch => {
        dispatch({
            type: inmCaractTypes.MOSTRAR_CARACTERISTICAS,
            payload: getInmCaract()
        })
    }
}
export function mostrarCaracteristicasSinoEsta() {
    return (dispatch, getState) => {
        const caracteristicas = getState().caracteristicas;
        if (Object.keys(caracteristicas.list).length || caracteristicas.isFetching) return false;
        dispatch({
            type: inmCaractTypes.MOSTRAR_CARACTERISTICAS,
            payload: getInmCaract()
        })
    }
}
export function eliminarCaracteristica(id) {
    return dispatch => {
        dispatch({
            type: inmCaractTypes.ELIMINAR_CARACTERISTICA,
            payload: delInmCaract(id)
        })
    }
}
export function agregarEditarCaracteristica(obj) {
    let cleanObj = _.omitBy(obj, (v) => !v);
    return dispatch => {
        dispatch({
            type: inmCaractTypes.AGREGAR_EDITAR_CARACTERISTICA,
            payload: cleanObj.carId ? editInmCaract(cleanObj) : addInmCaract(cleanObj)
        }).then(history.goBack)
    }
}