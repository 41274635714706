import {inmuebleTypes } from './types';
import {getInmuebles,addInmueble ,editInmueble, delInmueble, changeInmPublicar} from '../../services';
import history from "../../helpers/history"


export function agregarEditarInmueble(obj) {
    return dispatch => {
        dispatch({
            type: inmuebleTypes.AGREGAR_EDITAR_INMUEBLE,
            payload: obj.inmId ? editInmueble(obj) : addInmueble(obj)
        }).then(()=>{
            history.replace("/Inmuebles",null);
        })
    }
}
export function mostrarInmuebles(obj) {
    return (dispatch) => {
       // if (Object.keys(getState().inmuebles.list).length) return false;
        dispatch({
            type: inmuebleTypes.MOSTRAR_INMUEBLES,
            payload: getInmuebles(obj)
        })
    }
}
export function toggleInmueblePublico(inmId) {
    return (dispatch) => {
        dispatch({
            type: inmuebleTypes.CAMBIAR_INMUEBLE_PUBLICO,
            payload: changeInmPublicar(inmId)
        })
    }
}
export function mostrarInmuebleById(id) {
    return dispatch => {
        dispatch({
            type: inmuebleTypes.MOSTRAR_INMUEBLE_POR_ID,
            payload: getInmuebles(parseInt(id))
        })
    }
}
export function eliminarInmueble(id) {
    return dispatch => {
        dispatch({
            type: inmuebleTypes.ELIMINAR_INMUEBLE,
            payload: delInmueble(id)
        }).then(()=>{
            history.replace("/Inmuebles",null);
        })
    }
}
