import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";
import {mostrarMensajes,marcarMsjLeido} from "../../redux/actions/mensajeActions";
import {isMobile} from "../../helpers/browser_detect";

import {
    TextField, Grid, Button, Dialog, DialogContent, makeStyles, Fab,
    Checkbox, FormControlLabel, DialogActions, Card, CardContent, Typography, CardActions
} from "@material-ui/core";

import { formatDate} from '../../helpers';
import downloadFiles from '../../helpers/downloadFiles';
import {
    ButtonResponsive, SeacrhAppBar, LoadingProgress, ListViewMode, AppBar,
    MostrarError, FilteredAlert, HtmlTooltip, Pagination
} from "../../components";




import {SpeedDialAction,SpeedDial} from "@material-ui/lab";
import {Link as LinkIcon,EditOutlined,Share,CloudDownloadOutlined, FilterList} from "@material-ui/icons";
import Strings from '../../assets/strings'
import {Link} from "react-router-dom";
const useStyles = makeStyles(theme => ({
    root: {
        overflow: "hidden"
    },
    fb: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    speedDial:{
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    sDAction:{
        "& .MuiSpeedDialAction-staticTooltipLabel":{
            color: theme.palette.secondary.main,
            border: "1px solid " + theme.palette.secondary.main,
            boxShadow: "none",
            padding: "0px 10px",
            whiteSpace: "nowrap",
            fontSize: ".9rem",
        },
        "& svg":{
            color:theme.palette.primary.main,
        }

    }
}));
const initialState={
    name:'',
    fechaDesde: '',
    fechaHasta: '',
    estado: '',
    //pagination
    page: 0,
    pageSize:15,
    orderBy:"",
    orderByIsDescending:""
}
function Mensajes(props) {
     const classes = useStyles();
    const [open, setOpen] = useState(false);

    const [openFilter, setOpenFilter] = useState(false)
    const [filter, setFilter] = useState(initialState);

    const { mostrarMensajes,mensajes: { list,isFetching,error,filtered} } = props;
    const isEmpty = (list.length === 0);

    useEffect(() => {
        mostrarMensajes();
    }, [mostrarMensajes]);
    //set filtered params
    useEffect(()=>setFilter({...initialState,...filtered}),[setFilter,filtered])


    const handleFilter = e => {
        const {name, value} = e.target;
        if(name === "name" || name === "page" || name === "pageSize") return mostrarMensajes({...filter,page:null,[name]:value});

        setFilter({...filter,page:null,[name]:value});
    };
    const resetFilter = () => {
        setOpenFilter(false);
        mostrarMensajes({});
    };
    const searchFilter = () => {
        setOpenFilter(false);
        mostrarMensajes(filter);
    };
    // const tableSortProps = orderBy => {
    //     return {
    //         active:filter.orderBy === orderBy,
    //         direction: filter.orderByIsDescending ? "desc" : 'asc',
    //         onClick:()=> mostrarMensajes({...filter, orderBy,page:null, orderByIsDescending: !filter.orderByIsDescending})
    //     }
    // }
    const url = `https://gestinmueble.com/${props.userName}`;


    return (
        <>
            <AppBar title={"Mensajes"}>
                <SeacrhAppBar name="search" value={filter.search} onChange={handleFilter}/>
                <ButtonResponsive style={{display:"none"}} icon={<FilterList/>} text={Strings.filtrar} onClick={() => setOpenFilter(!openFilter)}/>
                <ButtonResponsive style={{display:"none"}} icon={<CloudDownloadOutlined/>} text={Strings.exportar} disabled={isEmpty} onClick={()=>downloadFiles(filter,"mensajes")}/>
            </AppBar>
            <Dialog open={openFilter} onClose={()=>setOpenFilter(false)} maxWidth={"xs"}>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label={Strings.fechaDesde}
                                type="date"
                                name="fechaDesde"
                                value={filter.fechaDesde}
                                onChange={handleFilter}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <TextField
                                label={Strings.fechaHasta}
                                type="date"
                                name="fechaHasta"
                                value={filter.fechaHasta}
                                onChange={handleFilter}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <FormControlLabel
                            control={<Checkbox checked={filter.verAnulados} onChange={handleFilter} name="verAnulados" />}
                            label={Strings.ver+" "+Strings.anulado}
                            labelPlacement="start"
                        />
                    </Grid>
                    <br/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={resetFilter}>{Strings.cancelar}</Button>
                    <Button color="primary" onClick={searchFilter}>oK</Button>
                </DialogActions>
            </Dialog>
            <section className={"contentInner "+classes.root} style={isFetching?{opacity:.8}:{opacity:1}}>
                {isFetching && isEmpty && <LoadingProgress vh/>}

                {error && <MostrarError errors={error}/>}
                {!!list.pageSize &&
                <ListViewMode pageRows={list.pageSize} pageTotalRows={list.totalRecordCount}/>}
                {!!Object.keys(filtered || {})?.length &&  <FilteredAlert onClose={resetFilter} filter={filtered}/>}

                <Grid container spacing={3}>
                    {list.length > 0 && list.map((item, i) =>
                        <Grid item xs={12} sm={6} md={4} key={i}>


                            <Card className={(item.menLeido ? "":"border-primary")} variant="outlined"


                                //onClick={()=>item.menLeido ? null: props.marcarMsjLeido(item.menId)}
                            >
                                <CardContent>
                                    <Typography color="primary">
                                        {item.menNombre}
                                    </Typography>
                                    <Typography color="textSecondary" variant={"caption"} gutterBottom>
                                        {formatDate(item.menFecha,"DD/MM/YYYY")}
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        {item.menComentario}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button size="small" component={"a"} href={"tel:"+item.menTelefono}>{item.menTelefono}</Button>
                                    <Button size="small" component={Link} to={"/Inmueble/"+item.inmId}>{Strings.inmueble}</Button>

                                </CardActions>
                            </Card>
                        </Grid>
                    )}
                </Grid>

                <Pagination
                    empty={Boolean(isEmpty && !isFetching)}
                    count={list.totalRecordCount || 0}
                    rowsPerPage={filter.pageSize}
                    page={list.pageNo -1}
                    onChangePage={(e,page)=>handleFilter({target:{name:"page",value:page+1}})}
                    onChangeRowsPerPage={handleFilter}
                />
            </section>
            {isMobile.anyWebView()?(
                <SpeedDial
                    ariaLabel="Short Cut"
                    className={classes.speedDial}
                    icon={<LinkIcon/>}
                    onClose={()=>setOpen(false)}
                    onOpen={()=>setOpen(true)}
                    open={open}
                >
                    <SpeedDialAction
                        classes={{staticTooltip:classes.sDAction}}
                        icon={<Share/>}
                        tooltipTitle={"Compartir"}
                        tooltipOpen
                        FabProps={{
                            onClick: ()=>{
                                isMobile.AndroidWebView()? window.Android.shareLink(url): window.webkit.messageHandlers.shareLink.postMessage(url);
                            }
                        }}
                    />
                    <SpeedDialAction
                        classes={{staticTooltip:classes.sDAction}}
                        icon={<EditOutlined/>}
                        tooltipTitle={"Abrir"}
                        tooltipOpen
                        FabProps={{
                            component: "a",
                            target:"_blank",
                            rel:"noopener noreferrer",
                            href: url
                        }}
                    />
                </SpeedDial>

            ):(
                <HtmlTooltip
                    title={Strings.linkCatalogoMsj}
                >
                    <Fab color="primary" className={classes.fb} href={url} target={"_blank"} rel="noopener noreferrer">
                        <LinkIcon />
                    </Fab>
                </HtmlTooltip>
            )}

        </>
    );
}
const mapStateToProps = (state) => ({
    mensajes: state.mensajes,
    viewTableList: state.theme.viewTableList,
    userName: state.auth.user.usuInicioSesion,

})
export default connect(mapStateToProps, { mostrarMensajes,marcarMsjLeido})(Mensajes);
