import React, {useState, useEffect} from 'react';
import {Button, Dialog, DialogContent, TextField, DialogActions, Grid, useMediaQuery, IconButton, Menu, MenuItem
} from "@material-ui/core";
import {connect} from "react-redux";
import {agregarEditarAgente,toggleLock,eliminarAgente,editarContrasena} from "../../redux/actions/agentesActions";
import Strings from "../../assets/strings"
import SelectRol from "../../components/selects/SelectRol";
import TopBarDialog from "../../components/topBar/TopBarDialog";
import {SlideLeft} from "../../components/transitions";
import {MoreVert} from "@material-ui/icons";
import {useHistory,useLocation} from "react-router-dom";
import {nullToEmptyString} from "../../helpers";
import {MostrarError,LoadingProgress,ConfirmDialog} from "../../components";
import {TextFieldPhone} from "../../components/CustomTextField";

const initialState={
    usuInicioSesion: "",
    usuClave: "",
    usuClave2:"",
    usuNombres: "",
    usuApellidos: "",
    usuEmail: "",
    usuTelefono: "",
    rolId: "",
    usuUIColor: "",
    rutId: "",
};
function Crear(props) {
    const {goBack,...history} = useHistory();
    const {state:urlState} = useLocation();

    const smVewport = useMediaQuery((theme)=>theme.breakpoints.down('sm'));
    const [showMenu, setShowMenu] = useState(null);
    const [showFormPass, setShowFormPass] = useState(false);
    const [errorPass, setErrorPass] = useState("");

    const {eliminarAgente} = props;

    const [state, setState] = useState(initialState);

    useEffect(() => {
        setShowFormPass(false);
        if(urlState)
            setState(state=>({...state,...nullToEmptyString(urlState)}));
        else
            setState(initialState);
    }, [setState,setShowFormPass, urlState]);
    const eliminar  = () =>{
        eliminarAgente(state.usuId)
    };
    const handleChange = e => {
        const {name, value} = e.target;
        setState({...state,[name]:value})
    };
    const handleSubmit = e => {
        e.preventDefault();
        setErrorPass("");
        let usuClave = null;
        if(showFormPass || !state.usuId){
            usuClave = state.usuClave;
            if(usuClave !== state.usuClave2){
                return setErrorPass(Strings.contrasenaNoMatchMjs)
            }
        }
        if(showFormPass)
            props.editarContrasena({usuInicioSesion:state.usuInicioSesion, usuClave});
        else
            props.agregarEditarAgente({...state,usuClave, usuClave2:null,});

    };

    return (
        <>
            <Dialog open={Boolean(urlState)} onClose={goBack}
                    {...(smVewport ? {TransitionComponent: SlideLeft}:{})}
                    fullScreen={smVewport}>

                <TopBarDialog title={(state.usuId?Strings.actualizar:Strings.crear)+" "+Strings.agente} onClose={goBack}>
                    {!showFormPass && !!state.usuId &&
                    <>
                        <IconButton size={"small"}
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={(e)=>setShowMenu(e.currentTarget)}
                                    color="inherit"
                        >
                            <MoreVert />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={showMenu}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(showMenu)}
                            onClose={()=>setShowMenu(null)}
                        >
                            <MenuItem onClick={()=>{setShowMenu(null);props.toggleLock(state.usuId)}}>{state.usuEstado === 1 ? Strings.bloquear:Strings.desbloquear}</MenuItem>
                            <MenuItem onClick={()=>{setShowMenu(null);setShowFormPass(true)}}>{Strings.contrasena}</MenuItem>
                            <MenuItem onClick={()=>{
                                setShowMenu(null);
                                history.push(history.location.pathname,{...state,confirmDialog:true})}}>{Strings.eliminar}</MenuItem>
                        </Menu></>}
                </TopBarDialog>
                <DialogContent>
                    <MostrarError errors={errorPass?errorPass:props.error}/>
                    {props.isPending && <LoadingProgress/>}
                    <br/>
                    <form id="form1" onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            {!showFormPass && <>
                                <Grid item xs={6}>
                                    <TextField
                                        name="usuInicioSesion"
                                        value={state.usuInicioSesion}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 50}}
                                        required
                                        label={Strings.nombreDeUsuario}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        name="usuNombres"
                                        value={state.usuNombres}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 50}}
                                        required
                                        label={Strings.primerNombre}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        name="usuApellidos"
                                        value={state.usuApellidos}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 50}}
                                        required
                                        label={Strings.apellidos}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        name="usuEmail"
                                        value={state.usuEmail}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 50}}
                                        label={Strings.email}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextFieldPhone
                                        name="usuTelefono"
                                        value={state.usuTelefono}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 20}}
                                        required
                                        label={Strings.telefono}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectRol
                                        name="rolId"
                                        value={state.rolId}
                                        required
                                        onChange={handleChange}
                                        label={Strings.rol}
                                    />
                                </Grid></>}
                            {(showFormPass || !state.usuId) &&
                            <>
                                <Grid item xs={6}>
                                    <TextField
                                        name="usuClave"
                                        value={state.usuClave}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 50}}
                                        required
                                        label={Strings.contrasena}
                                        type="password"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        name="usuClave2"
                                        value={state.usuClave2}
                                        onChange={handleChange}
                                        required
                                        label={Strings.confirmar+" "+Strings.contrasena}
                                        type="password"
                                        fullWidth
                                    />
                                </Grid>
                            </>
                            }
                        </Grid>
                    </form>
                    <br/>
                </DialogContent>
                <DialogActions>
                    {showFormPass && <Button onClick={()=>setShowFormPass(false)} color="primary">{Strings.cancelar}</Button>}

                    <Button type={"submit"} form={"form1"}  color="primary" disabled={props.isPending}>
                        {!!state.usuId ? Strings.actualizar : Strings.guardar}
                    </Button>
                </DialogActions>

            </Dialog>
            <ConfirmDialog onConfirm={eliminar}/>

        </>
    );

}
const mapStateToProps = (state) => ({
    isPending: state.agentes.isPending,
    error: state.agentes.error,
});
export default connect(mapStateToProps, { agregarEditarAgente,toggleLock,eliminarAgente,editarContrasena })(Crear);
