import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {delInmueble} from "../../services";
import {
    Dialog,
    DialogActions,
    DialogContent,
    Checkbox,
    FormControlLabel,
    Button,
    makeStyles,
} from '@material-ui/core';
import Strings from "../../assets/strings";
import {MostrarError,LoadingProgress,TopBarDialog} from "../../components";
const useStyles = makeStyles(theme => ({
  cancelBox:{
    "& .content":{
      padding: "10px 10px 0 10px",
    },
    "&.danger header":{
        backgroundColor: theme.palette.error.light,
    }
  }
}));


export default function CancelInmuebleDialog({inmId}) {
    const {goBack,...history} = useHistory();

    const classes = useStyles();
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [eliminar, setEliminar] = useState(false);

    const submit = () => {
        setLoading(true)
        delInmueble(inmId,{eliminar}).then(res=>{
            setLoading(false);
            history.replace("/inmuebles")
        }).catch(error=>{
            setError(error.response)
            setLoading(false)
        })
    };
    return (
      <Dialog open={true} onClose={goBack} className={(eliminar?"danger ":"")+classes.cancelBox} maxWidth={"xs"} fullWidth>
        <TopBarDialog title={Strings.confirmEliminar} onClose={goBack} />
          <MostrarError errors={error}/>
          <DialogContent className="content">
          {loading && <LoadingProgress />}
              {eliminar?(
                  <p>{Strings.inmuebleYcontratoSeEliminara}</p>
              ):(
                  <></>
              )}
              <FormControlLabel
                  control={<Checkbox checked={eliminar} onChange={({target})=>setEliminar(target.checked)}/>}
                  label={Strings.eliminar+" "+Strings.contratos} />
        </DialogContent>
        <DialogActions>
          <Button onClick={goBack} color="default">{Strings.abordar}</Button>
          <Button onClick={submit} disabled={loading} color="secondary">{Strings.confirmar}</Button>
        </DialogActions>
      </Dialog>
    );
}




