const loginStyle = theme => ({
    video:{
        position: "absolute",
        top:0,
        left:0,
        height: "calc(100% - 50px)",
        width: "100%",
        zIndex:100,
    },
    root:{
       // background: "#f0f0f4 url('https://egemem.com/theme/bitter/v1/assets/img/bg.png') no-repeat center center",
        //backgroundSize: "cover",
        background:"#f5f5f5",
        textAlign:"center",
        padding:10,
        paddingBottom: 50,
        paddingTop:"6%",
        height: "100vh",
        boxSizing: "border-box",
        [theme.breakpoints.down('sm')]: {
            backgroundColor: "white",
        },
    },
    logo:{
        [theme.breakpoints.down('sm')]: {
            display: "none"
        },
        "& h1":{
            fontSize: 28,
            fontWeight: 500,
            lineHeight: 1,
        },
        "& h3":{
            color: "#868e96",
            marginBottom: 17,
            fontSize: "1rem"
        }
    },
    form:{
        textAlign:"left",
        margin: "9px auto",
        position: "relative",
        maxWidth: 367,
        padding: "28px 23px",
        backgroundColor: "#fff",
        marginBottom: 20,
        boxShadow:theme.shadows[3],
        [theme.breakpoints.down('sm')]: {
            boxShadow:"none",
            backgroundColor: "transparent",
        },
        "& label":{
            textAlign:"left",
           // transform: "translate(10px, 14px) scale(1)",
            fontSize: ".9rem",
        },
        "& input":{
            padding: "11px 14px",
        },
        "& .noAsterisk label span":{
            display:"none",
        }

    },
    moreInfo:{
        position: "absolute",
        width: "100%",
        left: 0,
        bottom: 0,
    },
    headline:{
        textAlign:"center",
        fontSize: "1.3rem",
    },
    checkCont:{
        textAlign:"left",
        width: "100%",
    },
    submitBtn:{
        marginTop:11,
        width:"100%"
    },
    contraLink:{
        textAlign:"left",
        color: "#868e96",
        textDecoration: "none",
        display: "block",
        marginTop: 10,
    },
    bmLink:{
        "& a":{
            color: theme.palette.primary.main,
            textTransform: "uppercase",
            textDecoration: "none"
        }
    },
    lang:{
        marginTop: 10,
        marginBottom: 13,
        "& label":{
            display:"block",
            fontSize: ".8em",
        },
        "& select":{
            padding: "2px 7px",
            fontSize: "1em",
        }
    }
});

export default loginStyle;
