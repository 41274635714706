export const colors = [
    {primary: { main: "#17a2b8"}, secondary: { main:"#28a745"}},
    {primary: { main: "#6f42c1"}, secondary: { main:"#e83e8c"}},
    {primary: { main: "#007bff"}, secondary: { main:"#dc3545"}},
    {primary: { main: "#9b7243"}, secondary: { main:"#fd7e14"}},
    {primary: { main: "#20c997"}, secondary: { main:"#28a745"}},
    {primary: { main: "#28a745"}, secondary: { main:"#17a2b8"}},
    {primary: { main: "#6610f2"}, secondary: { main:"#007bff"}},
    {primary: { main: "#e83e8c"}, secondary: { main:"#6f42c1"}},
    {primary: { main: "#fd7e14"}, secondary: { main:"#dc3545"}},
]


export function getColor(color) {

    let foundColor = colors.find(c => c.primary.main === color);
    if(foundColor) return foundColor;

    return colors[0]
}