import React, {Fragment, useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import { useDispatch, useSelector} from 'react-redux';
import _ from 'lodash';
import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    FormControl,
    Grid, Input,
    InputLabel,
    makeStyles,
    TableBody,
    TableCell,
    TableRow, Typography
} from '@material-ui/core';

import { mostrarInmuebles } from '../../redux/actions/inmuebleActions';
import {formatPeso, formatDate, hasPermissionTo, PERSMISOS} from '../../helpers';
import Pagination from "../../components/Pagination";

import InmuebleItem from "../../components/CustomListItem/itemInmueble";
import {MostrarError} from "../../components";
import {
    AppBar,
    ButtonResponsive,
    FilteredAlert,
    ListViewMode,
    LoadingProgress,
    PlusFab,
    SeacrhAppBar,
    TableSort
} from "../../components";
import Strings from "../../assets/strings";
import {SelectSmart} from "../../components/CustomTextField";
import {FilterList} from "@material-ui/icons";
import SelectEnum from "../../components/selects/SelectEnum";
const useStyles = makeStyles(theme => ({
    root:{
        [theme.breakpoints.only('xs')]: {
            padding: `0px ${theme.spacing(2)}px`,
        },
    }
}));
const initialState = {
    name: "",
    page: 1,
    tipo:"",
    habitaciones:1,
    banos:1,
    estado:"",
    gruId: "",
    precioMin: 0,
    precioMax: 0,
    numHab: "",
    numBanos: "",
    pageSize: 25,
    orderBy: "",
    orderByIsDescending: true,
}

export default function Inmuebles() {
    const classes = useStyles();

    const { push } = useHistory();

    const Dispatch = useDispatch();
    const inmuebles = useSelector(s=>s.inmuebles);
    const viewTableList = useSelector(state=>state.theme.viewTableList);
    const [openFilter, setOpenFilter] = useState(false);
    const [filter, setFilter] = useState(initialState);


    useEffect(()=>{
        Dispatch(mostrarInmuebles())
    },[Dispatch])

    const handleFilter = e => {
        const {name, value} = e.target;
        if(name === "name" || name === "page" || name === "pageSize"){
            return Dispatch(mostrarInmuebles({
                ...filter,
                page:null,
                ...(name === "name" && {estado: ""}),
                [name]:value
            }));
        }
        setFilter({...filter,page:null,[name]:value})
    };

    const tableSortProps = orderBy => {
        return {
            active:filter.orderBy === orderBy,
            direction: filter.orderByIsDescending ? "desc" : 'asc',
            onClick:()=>mostrarInmuebles({...filter,page:null,orderBy,orderByIsDescending:!filter.orderByIsDescending})
        };
    };
    const resetFilter = () => {
    }
    const { list, isFetching, error,filtered={}}  = inmuebles;

    const isEmpty = list.results && list.results.length === 0;

    let grouped = _.groupBy(list.results, 'grupo');




    const CardView = (
        <Grid container spacing={3}>
            {!isEmpty && Object.keys(grouped).map((property,i) =>
                <Fragment key={i}>
                    {property !== "null" && <Grid item xs={12}><Typography color={"primary"}>{property}</Typography></Grid>}
                    {grouped[property].map((item)=>
                         <Grid item xs={12} sm={6} md={4} lg={3} key={item.inmId}>
                             <InmuebleItem item={item} link={"/Inmueble/" + item.inmId}/>
                         </Grid>
                    )}

                </Fragment>
            )}
        </Grid>
    );
    const ListView = (
        <TableSort data={[
            {pro:"inmReferencia",text:Strings.referencia},
            {pro:"inmTipoDescripcion",text:Strings.tipo},
            {pro:"inmPrecioAlquiler",text:Strings.alquiler},
            {pro:"inmPrecioVenta",text:Strings.venta},
            {pro:"propietario",text:"Propietario"},
            {pro:"inmAreaConstruida",text:Strings.area +" "+Strings.construida},
            {pro:"inmHabitaciones",text:Strings.habitaciones},
            {pro:"inmBanos",text:Strings.banos},
            {pro:"inmEstacionamientos",text:Strings.estacionamientos},
            {pro:"inmFechaCreacion",text:Strings.fecha},
            {pro:"inmEstadoDescripcion",text:Strings.estado},
            {pro:"",text:"Público"},
        ]} onSort={tableSortProps} lessVh={205}>
            <TableBody>
                {list.results && list.results.map(item => {
                    return (
                        <TableRow key={item.inmId} onClick={() => push("/Inmueble/" + item.inmId)} className={(item.new && 'new')}>
                            <TableCell>{item.inmReferencia}</TableCell>
                            <TableCell>{item.inmTipoDescripcion}</TableCell>
                            <TableCell>{formatPeso(item.inmPrecioAlquiler, item.paiCodeMoneda )}</TableCell>
                            <TableCell>{formatPeso(item.inmPrecioVenta, item.paiCodeMoneda )}</TableCell>
                            <TableCell>{item.propietario}</TableCell>
                            <TableCell>{item.inmAreaConstruida}/{item.inmAreaTotal}</TableCell>
                            <TableCell>{item.inmHabitaciones}</TableCell>
                            <TableCell>{item.inmBanos}</TableCell>
                            <TableCell>{item.inmEstacionamientos}</TableCell>
                            <TableCell>{formatDate(item.inmFechaCreacion)}</TableCell>
                            <TableCell>{item.inmEstadoDescripcion}</TableCell>
                            <TableCell>{item.inmPublico?"Si":"No"}</TableCell>
                        </TableRow>)
                })}
            </TableBody>
        </TableSort>
    )

    return (
        <Fragment>
            <AppBar title={"Inmuebles"}>
                <SeacrhAppBar name="name" value={filter.name} onChange={handleFilter} />
                <ButtonResponsive style={{display:"none"}} icon={<FilterList/>} text={Strings.filtrar} onClick={() => setOpenFilter(true)} />

            </AppBar>
            <Dialog open={openFilter} onClose={()=>setOpenFilter(false)} fullWidth maxWidth={"xs"}>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel shrink>Estado</InputLabel>
                                <SelectSmart
                                    value={filter.estado}
                                    onChange={handleFilter}
                                    input={<Input name="estado" />}
                                    opciones={[
                                        {key:'',value:Strings.seleccione},
                                        {key:'2',value:Strings.nuevo},
                                        {key:'1',value:Strings.activo},
                                        {key:'0',value:Strings.inactivo},
                                        {key:'-2',value:Strings.todo},
                                    ]}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <SelectEnum group={"inmTipo"}
                                        name={"inmTipo"}
                                        label={Strings.tipo}
                                        value={filter.inmTipo}
                                        onChange={handleFilter}
                                        required
                            />
                        </Grid>
                    </Grid>
                    <br/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>setOpenFilter(false)}>{Strings.cancelar}</Button>
                    <Button color="primary" variant={"contained"} disableElevation onClick={()=>setOpenFilter(false)}>oK</Button>
                </DialogActions>
            </Dialog>
            <ListViewMode pageRows={list.pageSize} pageTotalRows={list.totalRecordCount} lg>
                <FilteredAlert onClose={resetFilter} filter={filtered}/>
            </ListViewMode>
            <section className={"contentInner "+classes.root} style={isFetching?{opacity:.8}:{opacity:1}}>
                {isFetching && !list.results && <LoadingProgress vh/>}

                <MostrarError errors={error}/>

                {viewTableList ? ListView : CardView}
                <Pagination
                    empty={Boolean(isEmpty && !isFetching)}
                    count={list.totalRecordCount || 0}
                    rowsPerPage={filter.pageSize}
                    page={list.pageNo - 1}
                    onChangePage={(e,page)=>handleFilter({target:{name:"page",value:page+1}})}
                    onChangeRowsPerPage={handleFilter}
                />

            </section>
            {hasPermissionTo(PERSMISOS.inmueblesCrear) &&
            <PlusFab component={Link} to="/CrearInmueble" />
            }
        </Fragment>
    );
}

