import React from 'react';
import { connect } from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {Toolbar, Typography, useMediaQuery,IconButton} from '@material-ui/core';
import {Menu as MenuIcon ,ArrowBack} from '@material-ui/icons';
import {toggleDrawer} from "../redux/actions/themeActions";
import AppBar from '@material-ui/core/AppBar';
import {useHistory,useParams} from "react-router-dom";
import Routes from "../routes";

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    width: "100%",
    boxShadow: "none",
  },
  toolbar:{
    padding: "0px "+theme.spacing(2)+"px",
  },
  under:{
    boxShadow: "none",
    [theme.breakpoints.up('md')]: {
      top:50,
      padding: "7px 4px",
      width: "inherit",
      backgroundColor: "white",
      borderBottom: "1px solid rgba(0,0,0,0.1)",
      color: "inherit",
      "& + .contentInner":{
        marginTop: 65
      },
    },
    [theme.breakpoints.down('sm')]: {
      "& *":{
        outline: "none",
        border:"none",
        color: theme.palette.common.white,
      },
      "& option":{
        color: theme.palette.common.black,

      }
    },

  },
  toggleBtn:{
    marginRight:theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      cursor:"pointer"
      //display: "none",
    },
  },
  title: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    fontSize:"1.25rem",
    fontWeight: 400,
    textTransform:"capitalize",
    "& button":{
      marginRight: theme.spacing(1),
    }
  }
}));

function TopAppBar({title,toggleDrawer,children}){
  const classes = useStyles();
  const {location,replace: replaceUrl} = useHistory();

  const UrlParams = useParams();


  const smVewport = useMediaQuery((theme)=>theme.breakpoints.down('sm'));

  const getBackUrl = () => {
    let topRoute = null;

    let pathname = location.pathname;

    for (const [key, value] of Object.entries(UrlParams)) {
      pathname = pathname.replace(value,":"+key);
    }
    let routesArray = Routes();
    routesArray.forEach(e=>{
      if(e.nestedRoutes?.length && e.path){
        let found = e.nestedRoutes.find(n=>n.path.toUpperCase() === pathname.toUpperCase());

        if(found) topRoute = e;
      }
    })
    return topRoute;

  };
  const topRoute = getBackUrl();

      return (
        <AppBar className={classes.root + " d-print-none " + (title?classes.under: "")}>
          <Toolbar className={classes.toolbar}>
            {(!title || (smVewport && !topRoute)) && <MenuIcon onClick={toggleDrawer} className={classes.toggleBtn}/> }

            <Typography variant="h1" color={title?"secondary":"inherit"} noWrap className={classes.title}>
              {(title)?(
                  <>
                    {topRoute &&
                      <IconButton size="small" color={"secondary"} onClick={()=>replaceUrl(topRoute.path)}> <ArrowBack/></IconButton>
                    }
                    {title}
                  </>
              ):(
                 <>GestInmueble</>
              )}

            </Typography>
            {children}
          </Toolbar>
        </AppBar>
      );
}
const mapStateToprops = (state) => ({
  drawerOpen: state.theme.drawerOpen
})
export default connect(mapStateToprops,{toggleDrawer})(TopAppBar);

