import React, {useState, useEffect} from 'react';
import {agregarEditarGrupo} from "../../redux/actions/gruposActions";
import {
    Button,
    Dialog,
    DialogContent,
    TextField,
    DialogActions,
    FormControl,
    InputLabel,
    Select, MenuItem, Grid, makeStyles
} from "@material-ui/core";
import {useHistory, useLocation} from "react-router-dom";
import {nullToEmptyString} from "../../helpers";
import {connect} from "react-redux";
import LoadingProgress from "../../components/LoadingProgress";
import Strings from "../../assets/strings"
import {colors} from "../../assets/colors";
import {iconPath} from "../../assets/iconPath";
import MapSearch from "../../components/MapSearch";
const useStyles = makeStyles(theme => ({

    root:{
        padding:theme.spacing(2),
    },
    divColor:{
        width:"100%",
        padding: "1px 7px",
        color: "white",
    },
    divIcon:{
        "&>div":{
            padding: "4px 5px !important",
            paddingRight: "16px !important",
            textAlign: "center !important",
            height: "21px  !important",
            [theme.breakpoints.down('sm')]: {
                height: "26px  !important",
            },
        }
    }
}));

const initialState = {
    gruId: null, gruDescripcion: "", gruColor: "", gruIcon:"",gruDireccion:"",gruDireccion2:"",gruCoordenadas:""
};
function Crear(props) {
    const classes = useStyles();

    const {goBack} = useHistory();
    const {state:urlState={}} = useLocation();


    const [state, setState] = useState(initialState);
    const {isPending} = props;

    useEffect(() => {
        if(!!urlState && urlState.gruId) {
            setState(state => ({...state, ...nullToEmptyString(urlState)}));
        }else {
            setState(initialState);
        }
    }, [setState, urlState]);

    const handleSubmit = e => {
        e.preventDefault();
        props.agregarEditarGrupo(state)
    };
    const handleMapChange = (obj)=>{
        let dir = {};
        if(obj.direccion) dir.gruDireccion = obj.direccion;
        if(obj.coordenadas) dir.gruCoordenadas = obj.coordenadas;

        return setState({...state,...dir});
    }
    return (
        <>
            <Dialog open={Boolean(urlState && urlState.hasOwnProperty("gruId"))} onClose={goBack} maxWidth={"xs"} fullWidth={true}>
                <DialogContent>
                    {isPending && <LoadingProgress />}
                    <form onSubmit={handleSubmit} id="form1">
                        <TextField
                            value={state.gruDescripcion}
                            onChange={(e) => setState({ ...state, gruDescripcion: e.target.value })}
                            inputProps={{maxLength: 50}}
                            autoFocus={!state.gruId}
                            autoComplete={"off"}
                            required
                            label={Strings.descripcion}
                            type="text"
                            fullWidth
                        />
                        <Grid container spacing={1}>
                            <Grid item xs={9}>
                                <FormControl fullWidth margin={"normal"}>
                                    <InputLabel id="usuUIColor">Color</InputLabel>
                                    <Select
                                        labelId="usuUIColor"
                                        name="usuUIColor"
                                        value={state.gruColor}
                                        onChange={(e) => setState({ ...state, gruColor: e.target.value })}

                                    >
                                        {colors.map(c=>

                                            <MenuItem key={c.primary.main} value={c.primary.main}>
                                                <div className={classes.divColor} style={{background: c.primary.main}}>{c.primary.main}</div>
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth margin={"normal"} >
                                    <InputLabel id="gruIcon">&nbsp;</InputLabel>
                                    <Select
                                        labelId="gruIcon"
                                        name="gruIcon"
                                        value={state.gruIcon}
                                        onChange={(e) => setState({ ...state, gruIcon: e.target.value })}
                                        className={classes.divIcon}
                                    >
                                        {iconPath.map(i=>

                                            <MenuItem key={i.name} value={i.name}>
                                                <svg className="MuiSvgIcon-root jss67"  color={state.gruColor}
                                                     viewBox="0 0 24 24" aria-hidden="true" tabIndex="-1"
                                                     data-ga-event-action="click">
                                                    <path
                                                        d={i.path}></path>
                                                </svg>
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} style={{marginBottom:8}}>
                                <MapSearch handleChange={handleMapChange}
                                           direccion={state.gruDireccion} coordenadas={state.gruCoordenadas}/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label={Strings.direccion + " 2"}
                                    value={state.gruDireccion2}
                                    placeholder={Strings.referenciaDireccion}
                                    onChange={({target}) => setState({ ...state, gruDireccion2: target.value })}
                                    inputProps={{maxLength: 100}}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>



                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={goBack} color="primary">{Strings.cancelar}</Button>
                    <Button type="submit" form={"form1"} color="primary" disabled={isPending}>
                        {!!state.gruId ? Strings.actualizar : Strings.guardar}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );

}
const mapStateToProps = (state) => ({
    isPending: state.grupos.isPending,
});
export default connect(mapStateToProps, { agregarEditarGrupo })(Crear);