import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { mostrarRolesSinoEsta } from '../../redux/actions/rolesActions';
import {FormControl, InputLabel} from "@material-ui/core";
import {SelectSmart} from "../CustomTextField";
import Strings from "../../assets/strings";

function SelectRol({value = "", name = "rolId", label, onChange, all, required, roles, mostrarRolesSinoEsta}) {

    useEffect((e) => {
        mostrarRolesSinoEsta()
    }, [mostrarRolesSinoEsta]);

    var options = (roles.list && roles.list.map(opc=>({key:opc.rolId,value:opc.rolDescripcion}))) || [];
    options.unshift({key:"",value:all?Strings.todo:Strings.seleccione})

    return (

        <FormControl fullWidth required={required}>
            <InputLabel shrink>{label}</InputLabel>
            <SelectSmart
                value={value}
                onChange={onChange}
                name={name}
                required={required}
                loading={roles.isFetching }
                opciones={options}
            />
        </FormControl>
    );
}

const mapStateToProps = (state) => ({
    roles: state.roles,
})
export default connect(mapStateToProps, { mostrarRolesSinoEsta })(SelectRol);
