import { generalesTypes, _FULFILLED, _PENDING, _REJECTED } from '../actions/types';
const initialState = {
    data: {},
    isFetching: false,
    isPending: false,
    error: null,
}

export default function index(state = initialState, action={}) {

    switch (action.type) {
        case generalesTypes.MOSTRAR_GENERALES + _PENDING: {
            return {
                ...state,
                isFetching: true,
                error: {}
            }
        }
        case generalesTypes.MOSTRAR_GENERALES + _FULFILLED: {
            let data = action.payload?.data;
            return {
                ...state,
                data,
                isFetching: false,
                error: {}
            }
        }
        case generalesTypes.MOSTRAR_GENERALES + _REJECTED: {
            const { data, status } = action.payload.response || { data:"Error no definido"};
            return {
                ...state,
                isPending: false,
                isFetching: false,
                error: { data, status }
            }
        }
        //Editar
        
        case generalesTypes.EDITAR_GENERALES + _PENDING: {
            return {
                ...state,
                isPending: true,
                error: {}
            }
        }
        case generalesTypes.EDITAR_GENERALES + _FULFILLED: {
            let data = action.payload?.data;

            return {
                ...state,
                data,
                isPending: false,
                showModal: false,
                error: {}
            }
        }
        case generalesTypes.EDITAR_GENERALES + _REJECTED: {
            const { data, status } = action.payload.response || { data:"Error no definido"};
            return {
                ...state,
                isPending: false,
                isFetching: false,
                error: { data, status }
            }
        }

        //set
        case generalesTypes.SET_GENERALES: {
            return {
                ...state,
                data: action.payload
            }
        }
    default:
      return state;
  }
}
