import {clienteTypes, _PENDING, _REJECTED, _FULFILLED } from '../actions/types';
const initialState = {
    list: {},
    filtered:{},
    byId: {},
    isFetching: false,
    isPending: false,
    error: null,
};
export default function index(state = initialState, action={}) {

    switch (action.type) {
        case clienteTypes.MOSTRAR_CLIENTES + _PENDING: {
            return {
                ...state,
                byId:{},
                isFetching: true,
                error: {}
            }
        }
        case clienteTypes.MOSTRAR_CLIENTES + _FULFILLED: {
            return {
                ...state,
                list: action.payload.data,
                filtered: action.payload?.config?.params ?? {},
                isFetching: false,
                byId:{},
                error: null
            }
        }
        case clienteTypes.MOSTRAR_CLIENTES + _REJECTED: {
            const { data, status } = action.payload.response || { data:"Error no definido"};

            return {
                ...state,
                isFetching: false,
                error: { data, status }
            }
        }
        //mostrar por id
        case clienteTypes.MOSTRAR_CLIENTE_POR_ID + _PENDING: {
            return {
                ...state,
                isFetching: true,
                error: {}
            }
        }
        case clienteTypes.MOSTRAR_CLIENTE_POR_ID + _FULFILLED: {
            return {
                ...state,
                byId: action.payload.data,
                isFetching: false,
            }
        }
        case clienteTypes.MOSTRAR_CLIENTE_POR_ID + _REJECTED: {
            return {
                ...state,
                isFetching: false,
            }
        }

        case clienteTypes.LIMPIAR_CLIENTE_POR_ID: {
            return {
                ...state,
                isPending: false,
                isFetching: false,
                byId: {},
            }
        }
        //agregar  o editar
        case clienteTypes.AGREGAR_EDITAR_CLIENTE + _PENDING: {
            return {
                ...state,
                isPending: true,
                error: {}
            }
        }
        case clienteTypes.AGREGAR_EDITAR_CLIENTE + _FULFILLED: {
            const { data } = action.payload;
            let list = state.list;
            list.results?
                list.results = list.results.filter(e => e.cliId !== data.cliId): list.results = [];
            data.new = true
            //var list = state.list.filter(e => e.carId !== data.carId)
            list.results.unshift(data)
            return {
                ...state,
                isPending: false,
                byId:{},
                error: {}
            }
        }
        case clienteTypes.AGREGAR_EDITAR_CLIENTE + _REJECTED: {
            const { data, status } = action.payload.response || { data:"Error no definido"};
            return {
                ...state,
                isPending: false,
                isFetching: false,
                error: { data, status }
            }
        }
        //editar calificacion
        case clienteTypes.EDIT_CLIENTE_RATING + _FULFILLED: {
            const { data } = action.payload;
            let list = state.list;
            let byId = {...state.byId,...data}; //solo se actualiza del modal que llena este objecto

            list.results?
                list.results = list.results.filter(e => e.cliId !== data.cliId): list.results = [];
            list.results.unshift(byId)

            return {
                ...state,
                list,
                byId,
            }
        }
        //Eliminar
        case clienteTypes.ELIMINAR_CLIENTE + _PENDING: {
            return {
                ...state,
                isPending: true,
                error: {}
            }
        }
        case clienteTypes.ELIMINAR_CLIENTE + _FULFILLED: {
            const { data: id } = action.payload;
            let list = state.list
            list.results = list.results.filter(e => e.cliId !== id)
            --list.totalRecordCount
            return {
                ...state,
                list,
                isPending: false,
                byId:{},
                error: {}
            }
        }
        case clienteTypes.ELIMINAR_CLIENTE + _REJECTED: {
            return {
                ...state,
                isPending: false,
            }
        }
        //modal details
        case clienteTypes.MOSTRAR_CLIENTE_POR_ID_ENCONTRADO:  {
            const { byId } = action.payload;
            return {
                ...state,
                byId
            }
        }

        default:
            return state;
    }
};

