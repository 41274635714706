const errorToNotifObj = (obj)=>{
    let notifObj = { title: '', message: '', autoDismiss: 5, };

    if(obj && obj.status === 404) return {...notifObj,title:"Error "+ obj.status};

    if(obj === "string"){
        notifObj.title = obj;
    }else if (obj.data) {
        let data = obj.data;

        if (typeof data === "string"){
            notifObj.title = data;
        }else if (typeof data === "object"){
            let { title,value} = data;
            if (title || value){
                notifObj.title = title || value;
            }else{
                notifObj.title = "Error!";
            }
        }
    }
    return notifObj
}
export default errorToNotifObj