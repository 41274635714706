import React, {useState, useEffect} from 'react';
import {agregarEditarConServicio} from "../../redux/actions/serviciosContratoActions";
import {
    Button,
    Dialog,
    DialogContent,
    TextField,
    DialogActions, makeStyles
} from "@material-ui/core";
import {useHistory, useLocation} from "react-router-dom";
import {nullToEmptyString} from "../../helpers";
import {connect} from "react-redux";
import LoadingProgress from "../../components/LoadingProgress";
import Strings from "../../assets/strings"
const useStyles = makeStyles(theme => ({

    root:{
        padding:theme.spacing(2),
    },
    divColor:{
        width:"100%",
        padding: "1px 7px",
        color: "white",
    },
    divIcon:{
        "&>div":{
            padding: "4px 5px !important",
            paddingRight: "16px !important",
            textAlign: "center !important",
            height: "29px  !important",
            [theme.breakpoints.down('sm')]: {
                height: "26px  !important",
            },
        }
    }
}));

const initialState = {
    serId: null, serDescripcion: ""
};
function Crear(props) {
    const classes = useStyles();

    const {goBack} = useHistory();
    const {state:urlState={}} = useLocation();


    const [state, setState] = useState(initialState);
    const {isPending} = props;

    useEffect(() => {
        if(!!urlState && urlState.serId) {
            setState(state => ({...state, ...nullToEmptyString(urlState)}));
        }else {
            setState(initialState);
        }
    }, [setState, urlState]);

    const handleSubmit = e => {
        e.preventDefault();
        props.agregarEditarConServicio(state)
    };

    return (
        <>
            <Dialog open={Boolean(urlState && urlState.hasOwnProperty("serId"))} onClose={goBack} maxWidth={"xs"} fullWidth={true}>
                <DialogContent>
                    {isPending && <LoadingProgress />}
                    <form onSubmit={handleSubmit} id="form1">
                        <TextField
                            value={state.serDescripcion}
                            onChange={(e) => setState({ ...state, serDescripcion: e.target.value })}
                            inputProps={{maxLength: 50}}
                            autoFocus
                            autoComplete={"off"}
                            required
                            label={Strings.descripcion}
                            type="text"
                            fullWidth
                        />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={goBack} color="primary">{Strings.cancelar}</Button>
                    <Button type="submit" form={"form1"} color="primary" disabled={isPending}>
                        {!!state.serId ? Strings.actualizar : Strings.guardar}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );

}
const mapStateToProps = (state) => ({
    isPending: state.servicios.isPending,
});
export default connect(mapStateToProps, { agregarEditarConServicio })(Crear);