import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {HtmlTooltip} from "../../components"
import { Link } from 'react-router-dom';
import { formatPeso } from '../../helpers';
import Strings from "../../assets/strings";

import defaultInmueble  from '../../img/defaultInmueble.jpg';
import {
  HotelOutlined,
  BathtubOutlined,
  DriveEtaOutlined,
  VisibilityOutlined, VisibilityOffOutlined
} from "@material-ui/icons"
import {Divider} from "@material-ui/core";
const useStyles = makeStyles((theme)=>({
  root: {
    maxWidth: "auto",
    "& a":{
      textDecoration: "none",
      color:"inherit"
    }
  },
  media:{
    position: "relative",
    borderBottom: "1px solid "+theme.palette.grey[300],
    "&>img +div":{
      position: "absolute",
      right: "5px",
      textAlign: "right",
      bottom: "5px",
      "& div.published":{
        background:theme.palette.secondary.main,
        color: "#fff",
        padding: "3px 10px",
        fontSize: ".8rem",
        marginBottom:3,
        display: "inline-block"
      },
    }
  },
  bottom:{
    padding: "0 8px",
    borderTop: "1px solid "+theme.palette.grey[300],
    background: theme.palette.grey[100],
    "& button":{
      padding: "2px 3px",
      minWidth:"auto !important",
      "& svg":{
        marginRight: 6
      },
      "& *":{
        fontSize: "1.1em"

      },
      "&:last-child":{
        marginLeft:"auto"
      }
    }
  }
}))
export default function InmuebleItem(props){
  const classes = useStyles();

  const { item, link } = props;
  // var backgroundImage = {
  //     backgroundImage: "url(" + { Background } + ")"
  // };
  return (
      <Card className={classes.root}>
        <Link to={link}>
          <div className={classes.media}>
            <CardMedia
                component="img"
                alt="Contemplative Reptile"
                height="140"
                image={item.urlImage ? item.urlImage : defaultInmueble}
                title="Contemplative Reptile"
            />
            <div>
              <div className="published">{item.inmEstadoDescripcion}</div>
            </div>
          </div>
          <CardContent>
            <Typography gutterBottom color={"primary"} variant="body2" component={"div"} noWrap>
              {item.inmDescripcion}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {Strings.alquiler}: {formatPeso(item.inmPrecioAlquiler)}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {Strings.venta}: {formatPeso(item.inmPrecioVenta)}
            </Typography>
          </CardContent>
        </Link>
        <CardActions className={classes.bottom}>

          <HtmlTooltip title={<><strong>{item.inmHabitaciones}</strong> Habitación/es</>}>
            <Button  size="small">
              <HotelOutlined />
              {item.inmHabitaciones}
            </Button>
          </HtmlTooltip>
          <HtmlTooltip title={<><strong>{item.inmBanos}</strong> baño/s</>}>
            <Button  size="small">
              <BathtubOutlined/>
              {item.inmBanos}
            </Button>
          </HtmlTooltip>
          <HtmlTooltip title={<><strong>{item.inmEstacionamientos}</strong> estacionamiento/s</>}>
            <Button  size="small">
              <DriveEtaOutlined/>
              {item.inmEstacionamientos}
            </Button>
          </HtmlTooltip>
            <Button  size="small" color={item.inmPublico? "primary":"default"}>
              {item.inmPublico ? <VisibilityOutlined/> : <VisibilityOffOutlined/>}
            </Button>
        </CardActions>
      </Card>
  );
}
