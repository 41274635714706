import React from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';
import {useHistory,useLocation} from "react-router-dom";
import Strings from "../assets/strings"

export default function ConfirmDialog({onConfirm,replace}) {
    let history = useHistory();
    const { state } = useLocation();
    const confirm = () => {
        if(onConfirm) onConfirm();
        if(replace){
            history.replace(replace)
        }else {
            history.goBack()
        }
    };

    return (
        <div>
            <Dialog
                open={Boolean(state && state.confirmDialog)}
                onClose={()=>history.goBack()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{Strings.eliminar}?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {Strings.confirmEliminar}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>history.goBack()}>
                        {Strings.cancelar}
                    </Button>
                    <Box style={{color:"red"}}>
                        <Button onClick={confirm} color="inherit" >
                            {Strings.eliminar}
                        </Button>
                    </Box>

                </DialogActions>
            </Dialog>
        </div>
    );
}
