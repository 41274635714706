import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Button, Hidden} from '@material-ui/core';
import {SelectSmart} from "./CustomTextField";

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    width: "100%",
    boxShadow: "none",
  },
  btn: {
    [theme.breakpoints.down('sm')]: {
      minWidth: 39,
    }
  },
  label:{
    position: "relative",
    "& .MuiInputBase-root":{
      position: "absolute",
      left: "0",
      width: "100%",
      marginTop: "0 !important",
      paddingRight: 0,
      opacity: "0"
    }
  }
}));
export default function ButtonSelect({smIcon,text,opcions,disabled,onChange}){
  const classes = useStyles();
  const [value] = useState("");


  const handleChange = (e)=>{
    const {value} = e.target
    onChange(value)
  }

  return (
          <label className={classes.label}>
            <Button  color="inherit" className={classes.btn} disabled={disabled}>
              {(text && smIcon)?(
                  <>
                    <Hidden smDown>{text}</Hidden>
                    <Hidden mdUp>{smIcon}</Hidden>
                  </>
                  ):(smIcon || smIcon)
              }
            </Button >
            <SelectSmart
                disabled={disabled}
                value={value}
                onChange={handleChange}
                opciones={opcions}
            />
          </label>
      );
}

