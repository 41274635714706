import React, {Suspense, lazy} from "react";
import {connect} from "react-redux";
import {useMediaQuery,useTheme} from '@material-ui/core';
import './App.scss';
import  history  from "./helpers/history";
import {Router, Route, Switch} from 'react-router-dom'
import { ThemeProvider , createMuiTheme } from '@material-ui/core/styles';
import { esES } from '@material-ui/core/locale';
import 'typeface-roboto';
import Login from "./views/cuenta/login";
import Register from "./views/cuenta/register";
import ForgotPassword from "./views/cuenta/forgotPassword";
import Reports from "./views/reports";
import {getColor} from "./helpers/colors";
import LoadingProgress from "./components/LoadingProgress";

const Layout = lazy(() => import('./layouts'));


const inputStyle = {
    MuiInputLabel:{
        root: {
            "&:not([class*='-outlined'])":{
                position: "initial !important",
                transform: "none !important",
                color: "inherit !important",
                marginBottom: "10px !important",

            }
        },
    },
    MuiInputBase:{
        root:{
            background: "white",
            "&:not([class*='MuiOutlinedInput'])":{
                border: "1px solid #e6e6e6",
                marginTop: "0px !important",
                "& [class*='-input']:not([class*='Pagination'])":{ //:not(select)
                    padding: "8px 15px !important",
                },
                "&:before, &:after":{
                    display:"none !important",
                }
            }
        }
    }
};
function App(props){
   const {usuUIColor} = props.auth.user;
    const smVewport = useMediaQuery(useTheme().breakpoints.down('sm'));

    const theme = createMuiTheme({
        palette: {
            // type: 'dark',
            ...getColor(usuUIColor),
            // Used by `getContrastText()` to maximize the contrast between
            // the background and the text.
            contrastThreshold: 3,
            // Used by the functions below to shift a color's luminance by approximately
            // two indexes within its tonal palette.
            // E.g., shift from Red 500 to Red 300 or Red 700.
            tonalOffset: 0.2,
        },
        mixins: {
            toolbar: {
                minHeight: 50,
            }
        },
        overrides: {
            // MuiLinearProgress:{
            //   root:{
            //     height:2,
            //   }
            // },
            MuiButton: {
                root: {
                    borderRadius: "0"
                },
            },
            MuiTableRow: {
                head: {
                    height: 34,
                },
                root: {
                    //height: 44,
                },
                footer:{
                    cursor: "auto !important",
                }
            },
            MuiTableCell: {
                root:{
                    "&:not([class*='sizeSmall'])":{
                        height: 44,
                        padding: ".5rem",
                        boxSizing: "border-box",
                    },
                },
                head: {
                    color: (usuUIColor || "#2196f3"),
                    borderBottom: (usuUIColor || "#2196f3") + " 2px solid"
                },
                body: {
                },
                footer:{
                    fontSize: "0.85rem"
                }
            },
            ...(!smVewport?inputStyle:{})
        }
    },esES);
      return (
          <ThemeProvider  theme={theme}>
              <Router history={history}>
                  <Switch>
                      <Route exact path='/login' component={Login} />
                      <Route exact path='/register' component={Register} />
                      <Route path="/reports/:type/:id?" component={Reports}/>
                      <Route path='/forgotPassword' component={ForgotPassword} />


                      {props.auth?.isAuthenticated? (
                          <Suspense fallback={<LoadingProgress/>}>
                              <Route path="/" component={Layout} />
                          </Suspense>
                      ):(
                          props.auth?.isFetching?(<LoadingProgress/>):(<Route path="/" component={Login}/>)
                      )}

                  </Switch>
              </Router>
          </ThemeProvider >
    );
}
const mapStateToProps = (state) => ({
    auth: state.auth
});
export default connect(mapStateToProps)(App);