import React, {useEffect, useState} from "react";
import {allCountries} from "material-ui-phone-number/src/country_data";
import MuiPhoneNumber from "material-ui-phone-number";
import TextField from "@material-ui/core/TextField";
import {getLSLang} from "../helpers";
import {connect} from "react-redux";
import {makeStyles} from "@material-ui/core";
const useStyles = makeStyles(theme => ({
    textFieldPhone:{
        "& input":{
            paddingLeft: "0px !important",
        },
        "&.hint input":{
            color: theme.palette.text.hint,
        }
    }
}));
const countries = ["ar","bo","cl","co","cr","cu","do","ec","es","gq","gt","hn","mx","ni","pa","pe","pr","py","sv","uy","ve"];

function TextFieldPhone({name, onChange,defaultC, flag = true,value, ...props}) {
    const classes = useStyles();
    const [defaultCountry, setDefaultCountry] = useState("");
    const [onlyCountries, setOnlyCountries] = useState(countries);

    useEffect(()=>{
        if(flag && defaultC && !value){
            let defaultCTlow = defaultC.toLowerCase();
            setDefaultCountry(defaultCTlow);
        }
    },[flag,defaultC,value,setDefaultCountry]);

    if(flag && defaultCountry){
        if(onlyCountries.includes(defaultCountry)) {
            return (
                <>
                    <MuiPhoneNumber
                        onlyCountries={onlyCountries}
                        defaultCountry={defaultCountry}
                        value={value}
                        {...props}
                        {...((name && onChange) && {
                            onChange: (val)=> onChange({target:{value: (val.length >= 5 ? val : "") ,name}})
                        })}
                        className={classes.textFieldPhone+" "+(!value ? "hint" :"" )}
                    />
                    <input type="text" value={value} onChange={()=>{}} required={props.required} style={{height: "0", padding: "0", margin: "0 auto", width: "0", opacity: "0"}}/>
                </>
            );
        }else{
            if(allCountries.find(c=>c.iso2 === defaultCountry)){
                setOnlyCountries([...countries,defaultCountry])
            }else{
                setDefaultCountry("do")
            }
        }
    }
    return <TextField type={"tel"} name={name} onChange={onChange} value={value} {...props} inputProps={{maxLength: 20}} />
}
const mapStateToProps = (state) => ({
    defaultC: localStorage._token ? getLSLang("c"): state.auth.user.genLocalidad
})
export default connect(mapStateToProps, {} )(TextFieldPhone);