import React, {useState, useEffect} from 'react';
import {Button, TextField, CircularProgress, Grid, FormControl, InputLabel,} from "@material-ui/core";
import {formatDate, nullToEmptyString} from "../../helpers";
import LoadingProgress from "../../components/LoadingProgress";
import {makeStyles} from "@material-ui/core/styles";
import {SelectSmart} from "../../components/CustomTextField";
import {getAdminEnums, getAppUsersById, putAppUser} from "../../services";
import {useParams} from 'react-router-dom'
import {AppBar} from "../../components";
import Strings from "../../assets/strings";
//import {SelectPais} from "../../components/selects";
import Swal from 'sweetalert2'
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {WhatsApp,Launch} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    root:{
        margin:theme.spacing(1)
    }
}));

const initialState={
    usuId:"",
    usuTipoPlan:"",
    usuTipo:"",
    usuInicioSesion:"-",
    usuNombres:"",
    usuApellidos:"",
    usuEmail:"",
    usuTelefono:"",
    clientes:"",
    inmuebles:"",
    pagos:"",
    ultimoPago:"",
    usuAviso:"",
    usuMaxInmuebles:"",
    usuMaxAgentes:"",
    usuMaxCajas:"",
    usuPais:"",
    usuFechaCreacion:"",
    usuMaster:"",
    usuEstado:"1",
    usuContactado:"",
    usuSubscriptionId:""
};
export default function Editar() {
    let { usuId:urlUsuId} = useParams()
    const classes = useStyles();
    const [state, setState] = useState(initialState);
    const [adminEnums, setAdminEnums] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if(urlUsuId){
            getAppUsersById(urlUsuId).then((res)=>{
                setState(state=>({...state,...nullToEmptyString(res?.data)}));
            }).finally(()=>setLoading(false))
        }
    }, [setState, urlUsuId]);
    useEffect(()=>{
        getAdminEnums().then(({data})=>{
            setAdminEnums(data);
        })
    },[])
    const handleChange = e => {
        const {name, value} = e.target;
        setState({...state,[name]:value})
    };
    const handleSubmit = e => {
        e.preventDefault();
        setLoading(true)
        let object = {
            usuEstado:state.usuEstado,
            usuId:state.usuId,
            usuTipoPlan:state.usuTipoPlan,
            usuEmail:state.usuEmail,
            usuTelefono:state.usuTelefono,
            usuAviso:state.usuAviso,
            usuMaxInmuebles:state.usuMaxInmuebles,
            usuMaxAgentes:state.usuMaxAgentes,
            usuMaxCajas:state.usuMaxCajas,
            usuNombres:state.usuNombres,
            usuApellidos:state.usuApellidos,
            usuContactado:state.usuContactado,
            usuPais:state.usuPais,
            usuSubscriptionId:state.usuSubscriptionId
        }
        putAppUser(object).then(({data})=>{
            setState(state=>({...state,...nullToEmptyString(data)}));
            Swal.fire({
                text:'Guardado con éxito!',
                icon:'success'
            })
        }).finally((e)=>{
            setLoading(false)
        })
    };

    const usuTipo = adminEnums?.tiposUsuarios?.find(e=> parseInt(e.key) === state.usuTipo);
    return (
        <>
            <AppBar title={state.usuInicioSesion} >
                <Button color="inherit" disabled={loading} type="submit" form="form1" value="Submit">
                    {loading? (
                        <span><CircularProgress size={14} color="inherit" /> &nbsp; {Strings.cargando}</span>
                    ):(
                        <span>{Strings.guardar}</span>
                    )}
                </Button>
            </AppBar>
            <div className={"contentInner "+classes.root}>
                {loading && <LoadingProgress vh/>}
                <form id="form1" onSubmit={handleSubmit} autoComplete="off">
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={4} md={3}>
                            <FormControl fullWidth>
                                <InputLabel shrink>Aviso</InputLabel>
                                <SelectSmart
                                    fullWidth
                                    value={state.usuAviso}
                                    onChange={handleChange}
                                    name={"usuAviso"}
                                    opciones={adminEnums["tiposAviso"] ? [{key:"",value:"Selelccione"},...adminEnums["tiposAviso"]]:[]}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <FormControl fullWidth>
                                <InputLabel shrink>Plan</InputLabel>
                                <SelectSmart
                                    fullWidth
                                    value={state.usuTipoPlan}
                                    onChange={handleChange}
                                    name={"usuTipoPlan"}
                                    opciones={adminEnums["planes"] ? adminEnums["planes"]:[]}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <TextField
                                name="usuMaxInmuebles"
                                value={state.usuMaxInmuebles}
                                label="Max Inmuebles"
                                onChange={handleChange}
                                type={"number"}
                                fullWidth
                            />

                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>

                            <TextField
                                name="usuMaxAgentes"
                                value={state.usuMaxAgentes}
                                label="Max Agentes"
                                onChange={handleChange}
                                type={"number"}
                                fullWidth
                            />

                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <TextField
                                name="usuMaxCajas"
                                value={state.usuMaxCajas}
                                onChange={handleChange}
                                label="Max Cajas"
                                type={"number"}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <FormControl fullWidth>
                                <InputLabel shrink>Estado</InputLabel>
                                <SelectSmart
                                    fullWidth
                                    value={state.usuEstado.toString()}
                                    onChange={handleChange}
                                    name={"usuEstado"}
                                    opciones={[
                                        {key:"1",value:"Activo"},
                                        {key:"0",value:"Cancelado"}

                                    ]}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            {/*<SelectPais*/}
                            {/*    name="usuPais"*/}
                            {/*    label={"País"}*/}
                            {/*    value={state.usuPais}*/}
                            {/*    onChange={handleChange}*/}
                            {/*    fullWidth*/}
                            {/*/>*/}
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <TextField
                                name="usuNombres"
                                label={Strings.nombres}
                                value={state.usuNombres}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <TextField
                                name="usuApellidos"
                                label={Strings.apellidos}
                                value={state.usuApellidos}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <TextField
                                name="usuEmail"
                                value={state.usuEmail}
                                onChange={handleChange}
                                label={Strings.email}
                                required
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="standard-adornment-password">{Strings.telefono}</InputLabel>
                                <Input
                                    id="standard-adornment-password"
                                    type={"tel"}
                                    name={"usuTelefono"}
                                    value={state.usuTelefono}
                                    onChange={handleChange}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={()=>window.open(`https://wa.me/${state.usuTelefono}?text=${Strings.hola}, ${state.usuNombres} ${state.usuApellidos}. `)}
                                            >


                                                <WhatsApp/>
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={6} sm={4} md={3}>
                            <FormControl fullWidth>
                                <InputLabel>Paypal</InputLabel>
                                <Input
                                    name={"usuSubscriptionId"}
                                    value={state.usuSubscriptionId}
                                    onChange={handleChange}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={()=>window.open("https://www.paypal.com/billing/subscriptions/"+state.usuSubscriptionId)}
                                            >
                                                <Launch/>
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                name="usuContactado"
                                label={Strings.comentario}
                                value={state.usuContactado}
                                onChange={handleChange}
                                rows={2}
                                multiline
                                fullWidth
                            />
                        </Grid>
                        <Grid item md={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <label>Fecha Registro: </label>
                                    {formatDate(state.usuFechaCreacion)}
                                </Grid>
                                <Grid item xs={6}>
                                    <label>Tipo: </label>
                                    {usuTipo?.value}
                                </Grid>

                                <Grid item xs={6}>
                                    <label>{Strings.prestatariosActivos}: </label>
                                    {state.clientes}</Grid>
                                <Grid item xs={6}>
                                    <label>{Strings.inmueblesActivos}: </label>
                                    {`${state.inmuebles} ${Strings.de} ${state.usuMaxInmuebles}`}</Grid>
                                <Grid item xs={6} ><label>Pagos realizados: </label>{state.pagos}</Grid>
                                {!!state.ultimoPago &&
                                <Grid item xs={6}>
                                    <label>Ultimo pago: </label>{formatDate(state.ultimoPago)}
                                </Grid>
                                }
                                {!!state.usuMaster &&
                                <Grid item xs={6}>
                                    <label>Usuario master: </label>
                                    {formatDate(state.usuMaster)}</Grid>
                                }
                                {!!state.usuOrderId &&
                                <Grid item xs={6}>
                                    <label>paypal OrderId: </label>
                                    {state.usuOrderId}</Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </>
    );

}
