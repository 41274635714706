import { ajusteTypes, _FULFILLED, _PENDING, _REJECTED } from '../actions/types';
const initialState = {
    data: {},
    isFetching: false,
    isPending: false,
    error: null,
}

const index = (state = initialState, action={}) => {

    switch (action.type) {
        case ajusteTypes.MOSTRAR_AJUSTES + _PENDING: {
            return {
                ...state,
                isFetching: true,
                error: {}
            }
        }
        case ajusteTypes.MOSTRAR_AJUSTES + _FULFILLED: {
            return {
                ...state,
                data: action.payload.data,
                isFetching: false,
                error: {}
            }
        }
        case ajusteTypes.MOSTRAR_AJUSTES + _REJECTED: {
            const { data, status } = action.payload.response || { data: "Error no definido" };
            return {
                ...state,
                isPending: false,
                isFetching: false,
                error: { data, status }
            }
        }
        //Editar ajustes
        case ajusteTypes.EDITAR_AJUSTES + _PENDING: {
            return {
                ...state,
                isPending: true,
                error: {}
            }
        }
        case ajusteTypes.EDITAR_AJUSTES + _FULFILLED: {
            return {
                ...state,
                data: action.payload.data,
                isPending: false,
                error: {}
            }
        }
        case ajusteTypes.EDITAR_AJUSTES + _REJECTED: {
            const { data, status } = action.payload.response || { data: "Error no definido" };
            return {
                ...state,
                isPending: false,
                isFetching: false,
                error: { data, status }
            }
        }
        //Editar plantilla de contrato
        case ajusteTypes.EDITAR_PLANTILLA_CONTRATO + _PENDING: {
            return {
                ...state,
                isPending: true, 
                error: {}
            }
        }
        case ajusteTypes.EDITAR_PLANTILLA_CONTRATO + _FULFILLED: {
            return {
                ...state,
                data: action.payload.data,
                isPending: false,
                error: {}
            }
        }
        case ajusteTypes.EDITAR_PLANTILLA_CONTRATO + _REJECTED: {
            const { data, status } = action.payload.response || { data: "Error no definido" };
            return {
                ...state,
                isPending: false,
                isFetching: false,
                error: { data, status }
            }
        }
        //Editar formato de recibo
        case ajusteTypes.EDITAR_FORMATO_RECIBO + _PENDING: {
            return {
                ...state,
                isPending: true,
                error: {}
            }
        }
        case ajusteTypes.EDITAR_FORMATO_RECIBO + _FULFILLED: {
            return {
                ...state,
                isPending: false,
                error: {}
            }
        }
        case ajusteTypes.EDITAR_FORMATO_RECIBO + _REJECTED: {
            const { data, status } = action.payload.response || { data: "Error no definido" };
            return {
                ...state,
                isPending: false,
                isFetching: false,
                error: { data, status }
            }
        }
        
    default:
      return state;
  }
}
export default index;
