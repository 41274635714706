import { pagoTypes, contratoTypes } from './types';
import { getPagos, addPago,delPago, getContratos } from '../../services';


export function mostrarPagos(params) {
    return dispatch => {
        dispatch({
            type: pagoTypes.MOSTRAR_PAGOS,
            payload: getPagos(params)
        })
    }
}
export function toggleModalPago() {
    return (dispatch) => {
        dispatch({ type: pagoTypes.TOGGLE_MODAL_PAGO })
    }
}
export function agregarPago(obj) {
    return dispatch => {
        const response = dispatch({
            type: pagoTypes.AGREGAR_PAGO,
            payload: addPago(obj)
        })
        response.then(() => {
            dispatch({ type: contratoTypes.MOSTRAR_CONTRATO_POR_ID, payload: getContratos(parseInt(obj.conId)) })
        })
    }
}
export function anularPago(conId,pagId) {
    return (dispatch) => {
        dispatch({
            type: pagoTypes.ANULAR_PAGO,
            payload: delPago(pagId)
        }).then(() => {
            dispatch({
                type: contratoTypes.MOSTRAR_CONTRATO_POR_ID,
                payload: getContratos(parseInt(conId))
            })
        })
    }
}
