import {rolTypes, _FULFILLED, _PENDING, _REJECTED} from '../actions/types';
const initialState = {
    list: [],
    isFetching: false,
    isPending: false,
    error: null,
};

export default function index(state = initialState, action={}) {
    switch (action.type) {
        case rolTypes.MOSTRAR_ROLES + _PENDING: {
            return {
                ...state,
                isFetching: true,
                error: {}
            }
        }
        case rolTypes.MOSTRAR_ROLES + _FULFILLED: {
            return {
                ...state,
                list: action.payload.data,
                isFetching: false,
                error: {}
            }
        }
        case rolTypes.MOSTRAR_ROLES + _REJECTED: {
            return {
                ...state,
                isPending: false,
                isFetching: false,
            }
        }
        //agregar  o editar
        
        case rolTypes.AGREGAR_EDITAR_ROL + _PENDING: {
            return {
                ...state,
                isPending: true,
                error: {}
            }
        }
        case rolTypes.AGREGAR_EDITAR_ROL + _FULFILLED: {
            const { data } = action.payload;
            let list = state.list.filter(e => e.rolId !== data.rolId);
            list.unshift(data);

            return {
                ...state,
                list,
                isPending: false,
                error: {}
            }
        }
        case rolTypes.AGREGAR_EDITAR_ROL + _REJECTED: {
            const { data, status } = action.payload.response || { data:"Error no definido"};
            return {
                ...state,
                isPending: false,
                isFetching: false,
                error: { data, status }
            }
        }
        //Eliminar
        case rolTypes.ELIMINAR_ROL + _PENDING: {
            return {
                ...state,
                isPending: true,
                error: {}
            }
        }
        case rolTypes.ELIMINAR_ROL + _FULFILLED: {
            const { data: id  } = action.payload;
            let list = state.list.filter(e => e.rolId !== id);
            return {
                ...state,
                list,
                isPending: false,
                error: {}
            }
        }
        case rolTypes.ELIMINAR_ROL + _REJECTED: {
            return {
                ...state,
                isPending: false,
                isFetching: false,
            }
        }
    default:
      return state;
  }
}
