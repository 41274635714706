import React, {useEffect, useState} from 'react';
import {useLocation,useHistory } from "react-router-dom";
import {connect} from "react-redux";
import {editarGenerales, mostrarGenerales} from "../../redux/actions/generalesActions";

import { Tabs, Tab, Button, CircularProgress, Grid, Typography, TextField,makeStyles,
    ListItem, ListItemText, ListItemSecondaryAction, Switch, List} from '@material-ui/core';
import {MostrarError,HtmlTooltip,AppBar} from "../../components";
import Strings from "../../assets/strings";
import {TextFieldPhone} from "../../components/CustomTextField";
import SelectFiles from "../../components/selects/SelectFiles"
import LoadingProgress from "../../components/LoadingProgress";
import {geoString, nullToEmptyString} from "../../helpers";

import ContratoEditor from "./contratoEditor";
import CamposFormularios from "./CamposFormularios";
import SelectEnum from "../../components/selects/SelectEnum";
import SelectPais from "../../components/selects/SelectPais";

const useStyles = makeStyles(theme => ({
    root:{
    },
    tabs:{
        marginBottom: theme.spacing(3)
    },
    form:{
        margin: theme.spacing(2)
    },
    subtitle1:{
        margin: theme.spacing(2)+"px auto"
    },
    avatar:{
        "& img":{
            maxWidth: 150
        },
        [theme.breakpoints.down('sm')]: {
            "& img":{
                //   borderRadius: "50%",
            }
        }

    }
}));

function Ajustes(props) {
    let history = useHistory();
    const { hash = null} = useLocation();

    const classes = useStyles();

    const [activedTab, setTab] = React.useState('empresa');
    const [state, setState] = useState({
        ajuId: "",
        ajuEmpresa: "",
        ajuEmpresaDireccion: "",
        ajuEmpresaTelefono: "",
        ajuEmpresaTelefono2: "",
        ajuEmpresaEmail:"",
        ajuEmpresaRNC: "",
        ajuEmpresaLogo: "",
        ajuLogoBase64:"",
        ajuDiasMora: "",
        ajuPorcMora: "",
        ajuTipoMora: 1,
        paiCodigo:"",
        ajuFormatoRecibo: new Set(),
        ajuPlantillaContrato: "string",
        ajuPlantillaContrato2: "string",
        ajuPlantillaContrato3: "string",
        ajuConfigCampos:{},
        parametros: [],

        // "ajuEmpresaDireccion2": "string",
        //
        // "paiCodigo": "string",



        genLocalidad: "",
        genIdioma: "",





    });
    const {mostrarGenerales, editarGenerales,generales: {data, isFetching, isPending, error}} = props

    useEffect(()=>{
        if(!!hash && ["#empresa","#recibo","#contrato","#camposFormularios"].includes(hash)){
            setTab(hash.replace("#",""));
        }
    },[hash,setTab])
    useEffect(()=> {
        mostrarGenerales();
    },[mostrarGenerales]);
    useEffect(()=>{
        let datos = {...nullToEmptyString(data)};
        try {datos = {...data,ajuFormatoRecibo: new Set(data.ajuFormatoRecibo.split(","))};}
        catch (e) {datos = {...data,ajuFormatoRecibo: new Set()};}

        setState((prev)=>({...prev,...datos}));
    },[setState,data])
    const handleChange = e => {
        const {value,name} = e.target
        setState({...state,[name]:value});
    };
    const handleReciboCheck  = e => {
        const {name, checked} = e.target
        let ajuFormatoRecibo = state.ajuFormatoRecibo;

        if(checked)
            ajuFormatoRecibo.add(name)
        else
            ajuFormatoRecibo.delete(name)

        setState({...state,ajuFormatoRecibo});
    };

    const onImgChange = (baseb4)=> {
        setState({...state, ajuLogoBase64: baseb4});
    }
    const submit = (e) =>{
        e.preventDefault();
        let datos = {...state,ajuFormatoRecibo: [...state.ajuFormatoRecibo].join(",")};
        delete datos.ajuEmpresaLogo;

        editarGenerales(datos);
    }

    return (
        <>
            <AppBar title={Strings.ajustes}>
                <Button disabled={isPending} type="submit" form="form1">
                    {isPending? (
                        <span><CircularProgress size={14} color="inherit" /> &nbsp; {Strings.cargando}</span>
                    ):(
                        <span>{Strings.guardar}</span>
                    )}
                </Button>
            </AppBar>
            <div className="contentInner">
                {isFetching && <LoadingProgress/>}
                <MostrarError errors={error}/>
                <Tabs value={activedTab}
                      onChange={(e,v)=>history.push("ajustes#"+v)}
                      variant={"scrollable"}
                      className={classes.tabs}
                >
                    <Tab value="empresa" label={Strings.empresa} />
                    <Tab value="recibo" label={Strings.recibo}/>
                    <Tab value="contrato" label={Strings.contrato} />
                    <Tab value="camposFormularios" label={Strings.formularios} />
                </Tabs>

                <form id="form1" onSubmit={submit}  className={classes.form} style={activedTab !== "empresa"?{display:"none"}:{}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={2}>
                            <div className={classes.avatar}>
                                <SelectFiles src={state.ajuLogoBase64 || state.ajuEmpresaLogo} onChange={onImgChange}/>
                            </div>
                        </Grid>
                        <Grid item md={10} >
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={4} md={3}>
                                    <TextField
                                        name="ajuEmpresa"
                                        label={Strings.empresa}
                                        value={state.ajuEmpresa}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 50}}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6} sm={4} md={3}>
                                    <TextFieldPhone
                                        name="ajuEmpresaTelefono"
                                        label={Strings.telefono}
                                        value={state.ajuEmpresaTelefono}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 20}}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6} sm={4} md={3}>
                                    <TextFieldPhone
                                        name="ajuEmpresaTelefono2"
                                        label={Strings.celular}
                                        value={state.ajuEmpresaTelefono2}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 20}}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6} sm={4} md={3}>
                                    <TextField
                                        name="ajuEmpresaRNC"
                                        label={geoString("rnc",true)}
                                        placeholder={geoString("rnc")}
                                        value={state.ajuEmpresaRNC}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 20}}
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item xs={6} sm={4} md={3}>
                                    <TextField
                                        name="ajuEmpresaEmail"
                                        label={Strings.email}
                                        value={state.ajuEmpresaEmail}
                                        type={"email"}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 50}}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={9} md={6}>
                                    <TextField
                                        name="ajuEmpresaDireccion"
                                        label={Strings.direccion}
                                        value={state.ajuEmpresaDireccion}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 100}}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3} >
                                    <SelectPais
                                        name="paiCodigo"
                                        label={Strings.nacionalidad}
                                        value={state.paiCodigo}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Typography color={"secondary"} variant={"subtitle1"} className={classes.subtitle1}>{Strings.mora}</Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={4} md={3}>
                            <HtmlTooltip title={Strings.diasMoraMsj} disableHoverListener>
                                <TextField
                                    name="ajuDiasMora"
                                    label={Strings.diasDeGracia}
                                    type="number"
                                    inputProps={{step:1,inputMode:"numeric"}}
                                    value={state.ajuDiasMora}
                                    autoComplete="off"
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </HtmlTooltip>
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <HtmlTooltip title={Strings.porcientoMoraMsj} disableHoverListener>
                                <TextField
                                    name="ajuPorcMora"
                                    label={Strings.porcentaje}
                                    type="number"
                                    inputProps={{step:"any",inputMode:"decimal"}}
                                    value={state.ajuPorcMora}
                                    autoComplete="off"
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </HtmlTooltip>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                            <SelectEnum group={"tiposMora"}
                                        name={"ajuTipoMora"}
                                        label={Strings.aplicarMoraA}
                                        value={state.ajuTipoMora}
                                        onChange={handleChange}/>
                        </Grid>
                    </Grid>
                </form>
                {activedTab === "contrato" &&
                <ContratoEditor values={{
                    ajuPlantillaContrato: state.ajuPlantillaContrato,
                    ajuPlantillaContrato2: state.ajuPlantillaContrato2,
                    ajuPlantillaContrato3: state.ajuPlantillaContrato3
                }} onChange={handleChange}/>
                }
                {activedTab === "camposFormularios" &&
                <CamposFormularios values={state.ajuConfigCampos} onChange={(ajuConfigCampos)=>setState({...state,ajuConfigCampos})}/>
                }
                {activedTab === "recibo" &&
                <div className="reciboCont" style={{maxWidth:600}}>
                    <List  className={classes.root}>
                        {
                            [
                                {id:"copia",text:Strings.copia},
                                {id:"mora",text:Strings.mora},
                                {id:"descuento",text:Strings.descuento},
                                {id:"pendiente",text:`${Strings.balancePendiente}`},
                                {id:"proximoPago",text:`${Strings.fecha} ${Strings.proximo} ${Strings.pago}`},
                                {id:"firma",text:Strings.firma},

                            ].map(s=>
                                <ListItem key={s.id}>
                                    <ListItemText id={s.id} primary={s.text} />
                                    <ListItemSecondaryAction>
                                        <Switch
                                            edge="end"
                                            name={s.id}
                                            onChange={handleReciboCheck}
                                            checked={state.ajuFormatoRecibo.has(s.id)}
                                            inputProps={{ 'aria-labelledby': s.id }}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            )
                        }

                    </List>
                </div>
                }
            </div>

        </>
    );
}


const mapStateToprops = (state) => ({
    generales: state.generales,
        parametros: state.enums?.list?.parametros||[],
})
export default connect(mapStateToprops,{mostrarGenerales, editarGenerales})(Ajustes);

