import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { mostrarGruposSinoEsta } from '../../redux/actions/gruposActions';
import {FormControl, IconButton, InputAdornment, InputLabel, TextField} from "@material-ui/core";
import {SelectSmart} from "../CustomTextField";
import Strings from "../../assets/strings";
import {Close} from "@material-ui/icons";

function SelectGrupo({ value = "",grupo="", name = "gruId",label=Strings.grupos, onChange, grupos,mostrarGruposSinoEsta}) {

    const [addNew, setAddNew] = useState(false);

    useEffect((e) => {
        mostrarGruposSinoEsta();
    }, [mostrarGruposSinoEsta]);


    var options = (grupos.list && grupos.list.map(opc=>({key:opc.gruId,value:opc.gruDescripcion}))) || [];
    options.unshift({key:"",value:Strings.seleccione})
    options.push({key:"-1",value:Strings.agregar+" "+Strings.nuevo})

    const handleChage = (e)=>{
        const {value} = e.target;
        if(value === "-1") setAddNew(true);

        let obj = {
            grupo:"",
            gruId:"",
            inmDireccion:"",
            inmDireccion2:"",
            inmCoordenadas:"",
        }

        let selected = grupos.list.find(e=>e.gruId === value);
        if(selected){
            obj = {
                grupo:selected.gruDescripcion,
                gruId:selected.gruId,
                inmDireccion:selected.gruDireccion || "",
                inmDireccion2:selected.gruDireccion2 || "",
                inmCoordenadas:selected.gruCoordenadas || ""
            }
        }

        onChange(obj)
    }
    return (

        <FormControl fullWidth>
            <InputLabel shrink>{label}</InputLabel>
            {addNew ?(
                <TextField
                    autoFocus
                    value={grupo}
                    onChange={({target})=>onChange({grupo:target.value})}
                    InputProps={{
                        endAdornment:  <InputAdornment position="end" style={{position: "absolute",right: 0}}>
                            <IconButton
                                onClick={()=>setAddNew(false)}
                            >
                                <Close fontSize={"small"}/>
                            </IconButton>
                        </InputAdornment>,
                    }}
                />
            ):(
                <SelectSmart
                    value={value}
                    onChange={handleChage}
                    name={name}
                    loading={grupos.isFetching }
                    opciones={options}
                />
            )}

        </FormControl>

    );
}

const mapStateToProps = (state) => ({
    grupos: state.grupos,
})
export default connect(mapStateToProps, { mostrarGruposSinoEsta })(SelectGrupo);
