
export const GET_ERRORS = 'GET_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const CHANGE_CURRENT_USER_INFO = 'CHANGE_CURRENT_USER_INFO';
export const USER_LOGOUT = 'USER_LOGOUT';

export const themeTypes = {
    CLOSE_DRAWER: 'CLOSE_DRAWER',
    OPEN_DRAWER: 'OPEN_DRAWER',
    CLOSE_PAYPAL_PLAN: 'CLOSE_PAYPAL_PLAN',
    OPEN_PAYPAL_PLAN: "OPEN_PAYPAL_PLAN",
    TOGGLE_VIEW_TABLE: 'TOGGLE_VIEW_TABLE',
    SET_INSTALL_BANNER_EVENT: 'SET_INSTALL_BANNER_EVENT'
};
export const dashBoardTypes = {
    MOSTRAR_DASHBOARD_DATA: 'MOSTRAR_DASHBOARD_DATA',
    MOSTRAR_NOTIFICACIONES: 'MOSTRAR_NOTIFICACIONES',
    MOSTRAR_CxC:"MOSTRAR_CxC"
};
export const usuarioTypes = {
    MOSTRAR_PERFIL: 'MOSTRAR_PERFIL',
    EDITAR_PERFIL: 'EDITAR_PERFIL',
};
export const inmCaractTypes = {
    MOSTRAR_CARACTERISTICAS: 'MOSTRAR_CARACTERISTICAS',
    AGREGAR_EDITAR_CARACTERISTICA: 'AGREGAR_EDITAR_CARACTERISTICA',
    ELIMINAR_CARACTERISTICA: 'ELIMINAR_CARACTERISTICA',
    LOADING_CARACTERISTICA: 'LOADING_CARACTERISTICA'
}
export const generalesTypes = {
    MOSTRAR_GENERALES: 'MOSTRAR_GENERALES',
    EDITAR_GENERALES: 'EDITAR_GENERALES',
    SET_GENERALES: 'SET_GENERALES'
};
export const servicioContratoTypes = {
    MOSTRAR_SERVICIOS_CONTRATO: 'MOSTRAR_SERVICIOS_CONTRATO',
    AGREGAR_EDITAR_SERVICIO_CONTRATO: 'AGREGAR_EDITAR_SERVICIO_CONTRATO',
    ELIMINAR_SERVICIO_CONTRATO: 'ELIMINAR_SERVICIO_CONTRATO',
    ABRIR_MODAL_SERVICIO_CONTRATO: 'ABRIR_MODAL_SERVICIO_CONTRATO',
    CERRAR_MODAL_SERVICIO_CONTRATO: 'CERRAR_MODAL_SERVICIO_CONTRATO'
}
export const grupoTypes = {
    MOSTRAR_RUTAS: 'MOSTRAR_RUTAS',
    AGREGAR_EDITAR_RUTA: 'AGREGAR_EDITAR_RUTA',
    ELIMINAR_RUTA: 'ELIMINAR_RUTA',
    ABRIR_MODAL_RUTA: 'ABRIR_MODAL_RUTA',
    CERRAR_MODAL_RUTA: 'CERRAR_MODAL_RUTA'
}
export const rolTypes = {
    MOSTRAR_ROLES: 'MOSTRAR_ROLES',
    AGREGAR_EDITAR_ROL: 'AGREGAR_EDITAR_ROL',
    ELIMINAR_ROL: 'ELIMINAR_ROL',
    ABRIR_MODAL_ROL: 'ABRIR_MODAL_ROL',
    CERRAR_MODAL_ROL: 'CERRAR_MODAL_ROL',
}
export const paisTypes = {
    MOSTRAR_PAISES: 'MOSTRAR_PAISES',
}
export const mensajeTypes = {
    MOSTRAR_MENSAJES: 'MOSTRAR_MENSAJES',
    MARCAR_MENSAJE_LEIDO: 'MARCAR_MENSAJE_LEIDO',
}
export const estadoTypes = {
    MOSTRAR_ESTADOS: 'MOSTRAR_ESTADOS',
}
export const appUserTypes = {
    MOSTRAR_APPUSERS: 'MOSTRAR_APPUSERS',
};
export const inmuebleTypes = {
    MOSTRAR_INMUEBLES: 'MOSTRAR_INMUEBLES',
    MOSTRAR_INMUEBLE_POR_ID: 'MOSTRAR_INMUEBLE_POR_ID',
    AGREGAR_EDITAR_INMUEBLE: 'AGREGAR_EDITAR_INMUEBLE',
    ELIMINAR_INMUEBLE: 'ELIMINAR_INMUEBLES',
    MOSTRAR_INMUEBLE_DETALLES: 'MOSTRAR_INMUEBLE_DETALLES',
    CAMBIAR_INMUEBLE_PUBLICO: 'CAMBIAR_INMUEBLE_PUBLICO'

}
export const usosMultiplesTypes = {
    MOSTRAR_USOS_MULTIPLES: 'MOSTRAR_USOS_MULTIPLES',
    AGREGAR_EDITAR_USO_MULTIPLE: 'AGREGAR_EDITAR_USO_MULTIPLE',
    ELIMINAR_USO_MULTIPLE: 'ELIMINAR_USO_MULTIPLE',
}
export const clienteTypes = {
    MOSTRAR_CLIENTES: 'MOSTRAR_CLIENTES',
    MOSTRAR_CLIENTE_POR_ID: 'MOSTRAR_CLIENTE_POR_ID',
    AGREGAR_EDITAR_CLIENTE: 'AGREGAR_EDITAR_CLIENTE',
    ELIMINAR_CLIENTE: 'ELIMINAR_CLIENTE',
    LIMPIAR_CLIENTE_POR_ID:"LIMPIAR_CLIENTE_POR_ID",
    MOSTRAR_CLIENTE_POR_ID_ENCONTRADO:"MOSTRAR_CLIENTE_POR_ID_ENCONTRADO"
}
export const contratoTypes = {
    MOSTRAR_CONTRATOS: 'MOSTRAR_CONTRATOS',
    MOSTRAR_CONTRATO_POR_ID: 'MOSTRAR_CONTRATO_POR_ID',
    AGREGAR_EDITAR_CONTRATO: 'AGREGAR_EDITAR_CONTRATO',
    ELIMINAR_CONTRATO: 'ELIMINAR_CONTRATO',
    AGREGAR_CON_NOTA: 'AGREGAR_CON_NOTA',
    ELIMINAR_CON_NOTA: 'ELIMINAR_CON_NOTA',
    TOGGLE_MODAL_CONTRATO: 'TOGGLE_MODAL_CONTRATO',
    TOGGLE_MODAL_EDIT_CONTRATO: 'TOGGLE_MODAL_EDIT_CONTRATO',
}
export const gastoTypes = {
    MOSTRAR_GASTOS: 'MOSTRAR_GASTOS',
    AGREGAR_GASTO: 'AGREGAR_GASTO',
    ELIMINAR_GASTO: 'ELIMINAR_GASTO'
};
export const pagoTypes = {
    MOSTRAR_PAGOS: 'MOSTRAR_PAGOS',
    MOSTRAR_PAGOS_DE_CONTRATO: 'MOSTRAR_PAGOS_DE_CONTRATO',
    AGREGAR_PAGO: 'AGREGAR_PAGO',
    ANULAR_PAGO: 'ANULAR_PAGO',
    TOGGLE_MODAL_PAGO: 'TOGGLE_MODAL_PAGO',
}
export const ajusteTypes = {
    MOSTRAR_AJUSTES: 'MOSTRAR_AJUSTES',
    EDITAR_AJUSTES: 'EDITAR_AJUSTES',
    EDITAR_PLANTILLA_CONTRATO: 'EDITAR_PLANTILLA_CONTRATO',
    EDITAR_FORMATO_RECIBO: 'EDITAR_FORMATO_RECIBO',
}
export const agenteTypes = {
    MOSTRAR_AGENTES: 'MOSTRAR_AGENTES',
    AGREGAR_EDITAR_AGENTE:"AGREGAR_EDITAR_AGENTE",
    EDITAR_CONTRASENA_AGENTE:"EDITAR_CONTRASENA_AGENTE",
    LOCK_TOGGLE_AGENTE:"LOCK_TOGGLE_AGENTE",
    ELIMINAR_AGENTE:"ELIMINAR_AGENTE",
};
export const enumsTypes = {
    MOSTRAR_ENUMS: 'MOSTRAR_ENUMS',
    SET_ENUMS: 'SET_ENUMS',
};
//COMPARTIDOS
export const _PENDING = '_PENDING';
export const _REJECTED = '_REJECTED';
export const _FULFILLED = '_FULFILLED';
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL'; 