import React from 'react';
import {Badge, ListItem, ListItemAvatar, Avatar, ListItemText, Divider, Typography} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import DefaultImgEmp from '../assets/img/defaultAvatar_empresa.jpg';
import NoImg from '../assets/img/defaultAvatar.jpg';

const useStyles = makeStyles(theme => ({
    status:{
        display: "block",
        width: 10,
        height: 10,
        background: "gray",
        border: "2px solid #fff",
        borderRadius: "50%",
        position: "absolute",
        right: 5,
        bottom: 5,
        "&.e0":{
            background: "red"
        },
        "&.e1":{
            background: "#01a855"
        },
        "&.e2":{
            background: "orange"
        }
    }
}));

export default function ClientListItem(props) {

    const classes = useStyles();
    const { cliente, onClick } = props;
    return (
        <>
            <ListItem button  onClick={()=>onClick(cliente.cliId)}>
                <ListItemAvatar>
                    <Badge
                        classes={{badge:classes.status +" e"+ cliente.cliEstado}}
                        color={"secondary"}
                        variant={"dot"}
                        overlap="circle"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                    >
                        <Avatar alt="" src={ cliente.cliAvatar?cliente.cliAvatar: (cliente.cliTipo === 2 ?DefaultImgEmp: NoImg)}/>
                    </Badge>

                </ListItemAvatar>

                <ListItemText
                    // secondary={
                    //     <Box display={"flex"} component={"span"} >
                    //         <Box  component={"span"} mr={1}>{Strings.capital}: {formatPeso(cliente.capitalPendiente)}</Box>
                    //         <Typography component={"span"} variant="body2">{Strings.balance}: {formatPeso(cliente.balancePendiente)}</Typography>
                    //     </Box>
                    //     }
                >
                    <Typography noWrap>
                        {cliente.cliNombres +" "+cliente.cliApellidos}
                    </Typography>
                </ListItemText>
            </ListItem>
            <Divider variant="inset" component="li" />
        </>
    )
}
