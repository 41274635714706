import {rolTypes} from './types';
import {getRoles, addRol, editRol, delRol} from '../../services';
import history from "../../helpers/history";

export function mostrarRoles(params) {
    return dispatch => {
        dispatch({
            type: rolTypes.MOSTRAR_ROLES,
            payload: getRoles(params)
        })
    }
}
export function mostrarRolesSinoEsta() {
    return (dispatch, getState) => {
        const roles = getState().roles
        if (Object.keys(roles.list).length || roles.isFetching) return false;
        dispatch({
            type: rolTypes.MOSTRAR_ROLES,
            payload: getRoles()
        })
    }
}
export function eliminarRol(id) {
    return dispatch => {
        dispatch({
            type: rolTypes.ELIMINAR_ROL,
            payload: delRol(id)
        })
    }
}
export function agregarEditarRol(obj) {
    return dispatch => {
        dispatch({
            type: rolTypes.AGREGAR_EDITAR_ROL,
            payload: obj.rolId ? editRol(obj) : addRol(obj) 
        }).then(()=>history.goBack())
    }
}
