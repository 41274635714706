import React, { useEffect } from 'react';
import { MostrarError } from "../../components/MostrarError";
import { connect } from 'react-redux';
import { mostrarEstados} from '../../redux/actions/estadosActions';

import AppBar from "../../components/appBar";
import {Table, TableBody, TableCell,TableHead, TableContainer, TableRow} from "@material-ui/core";
import LoadingProgress from "../../components/LoadingProgress";
import {formatDate} from "../../helpers";
function  Estados(props) {
    const { mostrarEstados } = props
    useEffect(() => {
        mostrarEstados();
    }, [mostrarEstados])

    const { estados: { list, isFetching, error } } = props;
    const isEmpty = list.length === 0;

    //sacar tablas unicas
    let estTabla = list.map(item => item.estTabla);
    let tablas = [...new Set(estTabla)];

    return(
        <>
            <AppBar title={"Estados"}></AppBar>
            <section className="contentInner" style={isFetching?{opacity:.8}:{opacity:1}}>
                {isFetching && isEmpty && <LoadingProgress vh/>}
                <MostrarError errors={error}/>
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Descripcion</TableCell>
                                <TableCell>Estado</TableCell>
                                <TableCell>Actualización</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {tablas.length > 0 && tablas.map((table, i) =>
                                <React.Fragment key={i}>
                                    <TableRow>
                                        <TableCell colSpan="4">{table.toUpperCase()}</TableCell>
                                    </TableRow>
                                    {list.length > 0 && list.map((item, i) =>
                                        item.estTabla === table &&
                                        <TableRow key={i}>
                                            <TableCell>{item.estDescripcion}</TableCell>
                                            <TableCell>{item.estEstado}</TableCell>
                                            <TableCell>{formatDate(item.estFechaActualizacion)}</TableCell>
                                        </TableRow>
                                    )}
                                </React.Fragment>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {isEmpty && <div className="isEmpty">No se encontraron registros</div>}
            </section>
        </>
    )
}
const mapStateToProps = (state) => ({
    estados: state.estados
})
export default connect(mapStateToProps, { mostrarEstados })(Estados);

