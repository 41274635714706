import {dashBoardTypes, _FULFILLED, _PENDING, _REJECTED} from '../actions/types';
const initialState = {
    isFetching: false,
    contratos:"0 / 0",
    inmueblesPublico:"0",
    ingresos:0,
    gastos:0,
    maxInmuebles:0,
    inmuebles:0,
    ingresosChart:[],
    solicitudes:0,
};

export default function index(state = initialState, action={}) {

    switch (action.type) {
        case dashBoardTypes.MOSTRAR_DASHBOARD_DATA + _PENDING: {
            return {
                ...state,
                isFetching: true,
            }
        }
        case dashBoardTypes.MOSTRAR_DASHBOARD_DATA + _FULFILLED: {
            const [widgets,ingresos,cxc] = action.payload;
            return {
                ...state,
                ...widgets.data,
                cxc:cxc.data,
                ingresosChart:ingresos.data,
                isFetching: false,
            }
        }
        case dashBoardTypes.MOSTRAR_DASHBOARD_DATA + _REJECTED: {
            return {
                ...state,
                isFetching: false,
            }
        }
        case dashBoardTypes.MOSTRAR_NOTIFICACIONES + _FULFILLED: {
            const {data} = action.payload;
            return {
                ...state,
                ...data,
            }
        }
    default:
      return state;
  }
}
