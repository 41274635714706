import {getLSLang} from "./index";
import Strings from "../assets/strings";

const objs = {
    AR:{
        cedula:"Documento nacional de identidad, DNI",
        rnc:"Código Único de Identificación Tributaria, CUIT"
    },
    ES:{
        cedula:"Documento nacional de identidad, DNI",
        rnc:"Código de Identificación Fiscal, CIF"
    },
    PE:{
        cedula:"Documento nacional de identidad, DNI",
        rnc:"Registro Único de Contribuyentes, RUC"
    },
    BO:{
        cedula:"Cédula de identidad, CI",
        rnc:"Número de Identificación Tributaria, NIT"
    },
    CL:{
        cedula:"Cédula de identidad, CI",
        rnc:"Rol Único Tributario, RUT"
    },
    CR:{
        cedula:"Cédula de identidad, CI",
        rnc:"Número de Identificación Tributaria Especial, NITE"
    },
    NI:{
        cedula:"Cédula de identidad, CI",
        rnc:"Registro Único de Contribuyentes, RUC"
    },
    UY:{
        cedula:"Cédula de identidad, CI",
        rnc:"Registro Único Tributario, RUT"
    },
    VE:{
        cedula:"Cédula de identidad, CI",
        rnc:"Registro de Información Fiscal, RIF"
    },
    CO:{
        cedula:"Cédula de ciudadanía, CC",
        rnc:"Número de Identificación Tributaria, NIT"

    },
    EC:{
        cedula:"Cédula de ciudadanía, CC",
        rnc:"Registro Único de Contribuyentes, RUC"
    },
    SV:{
        cedula:"Doc. único de identidad, DUI",
        rnc:"Número de Identificación Tributaria, NIT"
    },
    GT:{
        cedula:"Doc. personal de identificación, DPI",
        rnc: "Registro Tributario Unificado, RTU"
    },
    HN:{
        cedula:"Tarjeta de identidad, TDI",
        rnc: "Registro Tributario Nacional, RTN"
    },
    MX:{
        cedula:"Credencial de Elector, CURP",
        rnc:"Registro Federal de Contribuyentes,RFC"
    },
    PA:{
        cedula:"Cédula de identidad personal, CIP",
        rnc: "Número de Identificación Tributaria, NIT"
    },
    PY:{
        cedula:"Cédula de identidad civil, CIC/CI",
        rnc:"Registro Único de Contribuyentes, RUC"
    },
    PR:{
        cedula:"Identificación, ID",
        rnc:"Numeros de Identificacion del Contribuyente, TIN"
    },
    DO:{
        cedula:"Cédula de identidad y electoral, CIE",
        rnc:"Registro Nacional del Contribuyente, RNC"
    }
}

export default function geoString(str,acronimo){
    let lang = getLSLang("l");
    if(lang !== "es") return Strings[str];

    let pais = getLSLang("c");
    let item = objs[pais];

    if(!item) return Strings[str];

    let cedula = item.cedula.split(",");
    let rnc = item.rnc.split(",");



    switch (str){
        case "cedula":
            return  cedula[acronimo?1:0];
        case "rnc":
            return rnc[acronimo?1:0];
        default:
            return Strings[str];
    }
}
