const responsiveTable = {
    responsiveTable:{
        width: '100%',
        overflowX: 'auto',
        "& table":{
          minWidth: 700,
        }
    }
}
const actionCellTable = {
    tableCellActions: {
        //padding: "12px 8px !important",

        verticalAlign: "middle",
        textAlign: "left",
        whiteSpace: "nowrap",
        "& button, & a":{
            width: 27,
            height: 27,
            padding: 0,
            "& svg":{
                width: 17,
                height: 17
            }
        }
    },
    close: {
        color: "#f44336",
    },
};
const underTitle={

      filterButton: {
        //margin: theme.spacing.unit,
        minHeight: "auto",
        minWidth: 104,
        marginLeft: 4
    
      },
      underTitleSelect:{
        marginLeft: 4,
        border: '1px solid #ced4da',
        "& > div >div":{
            paddingLeft: 5,
        }
      },
      underTitleInput: {
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '5px 12px',
      },
      searchForm:{
          "& input":{
            border: '1px solid #ced4da',
            fontSize: 16,
            padding: '5px 12px',
          },
          "& button":{
            padding: "0px 7px",
            paddingTop: 3,
            background: "transparent",
            border: 0,
            marginLeft: "-34px",
            cursor: "pointer",
            verticalAlign: "top",
            "& svg":{
                width: 19,
            }
          }
      }
};
const switchControl = {
    switchIcon: {
        boxShadow: "0 1px 3px 1px rgba(0, 0, 0, 0.4)",
        color: "#FFFFFF !important",
        border: "1px solid rgba(0, 0, 0, .54)",
        transform: "translateX(-4px)!important"
      },
      switchIconChecked: {
        borderColor: "#9c27b0",
        transform: "translateX(0px)!important"
      },
      switchBar: {
        width: "30px",
        height: "15px",
        backgroundColor: "rgb(80, 80, 80)",
        borderRadius: "15px",
        opacity: "0.7!important"
      },
};
const checkControl={
    checkedIcon: {
        width: "15px",
        height: "15px",
        border: "1px solid rgba(0, 0, 0, .54)",
        borderRadius: "3px"
      },
      uncheckedIcon: {
        width: "0px",
        height: "0px",
        padding: "7.5px",
        border: "1px solid rgba(0, 0, 0, .54)",
        borderRadius: "3px"
      },
}
export const dropDownMenu = {

        "& >div":{
            boxShadow: "0px 1px 8px 0px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12)",
            borderRadius:1,
        },
        "& ul >*":{
            padding: "4px 14px",
            margin: "0 3px",
            fontSize: "14px",
            borderRadius:1,
            transition: "all 150ms linear",
            "&:hover":{
                color:"white",
                boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)",
            }
        }
    
}
export {
    responsiveTable,
    actionCellTable,
    underTitle,
    switchControl,
    checkControl
};