import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { mostrarAgentes} from '../../redux/actions/agentesActions';
import {
    Table, TableBody, TableCell, TableHead, TableContainer, TableRow, Button, useMediaQuery, List,
    ListItem, ListItemAvatar, Avatar, ListItemText, Typography, Divider
} from "@material-ui/core";
import Strings from "../../assets/strings"
import {LoadingProgress,Empty,PlusFab,AppBar} from "../../components";
import Crear from "./crear";
import {useHistory} from "react-router-dom";
import {ReactComponent as EmptySvg} from "../../assets/img/collaborators.svg";

function  Agentes(props) {
    let history = useHistory();

    const smVewport = useMediaQuery((theme)=>theme.breakpoints.down('sm'));
    const { mostrarAgentes } = props
    useEffect(() => {
        mostrarAgentes();
    }, [mostrarAgentes])
    const { agentes: { list, isFetching } } = props;
    const isEmpty = list.length === 0;
    return(
        <>
            <AppBar title={Strings.agentes}></AppBar>
            <section className="contentInner" style={isFetching?{opacity:.8}:{opacity:1}}>
                {isFetching && isEmpty && <LoadingProgress vh/>}

                {isEmpty && !isFetching ? (
                    <Empty Img={EmptySvg} title={Strings.aunNoTienes+" "+Strings.agentes}>
                        <p>{Strings.agentesHelpMsj}</p>
                    </Empty>
                ):(
                    smVewport ? (
                        <List>
                            {list.map(agente => (
                                <React.Fragment key={agente.usuInicioSesion}>
                                    <ListItem button alignItems="flex-start" onClick={()=>history.push(history.location.pathname,agente)} >
                                        <ListItemAvatar>
                                            <Avatar>{agente.usuNombres.charAt(0).toUpperCase()}</Avatar>
                                        </ListItemAvatar>
                                        <ListItemText secondary={Strings.grupo+": " + agente.grupo}>
                                            <Typography noWrap className={(agente.usuEstado !== 1) ? "text-red":""}>
                                                {agente.usuNombres + " " + agente.usuApellidos}
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <Divider variant="inset" component="li"/>
                                </React.Fragment>
                            ))}
                        </List>
                    ) : (
                        <TableContainer>
                            <Table aria-label="simple table" className={"hasOnclick"}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{Strings.nombreDeUsuario}</TableCell>
                                        <TableCell>{Strings.primerNombre}</TableCell>
                                        <TableCell>{Strings.apellidos}</TableCell>
                                        <TableCell>{Strings.email}</TableCell>
                                        <TableCell>{Strings.telefono}</TableCell>
                                        <TableCell>{Strings.rol}</TableCell>
                                        <TableCell>{Strings.estado}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!isEmpty && list.map(item =>
                                        <TableRow key={item.usuInicioSesion} className={(item.usuEstado !== 1) ? "text-red-deep":""}
                                                  onClick={()=>history.push(history.location.pathname,item)}>
                                            <TableCell> {item.usuInicioSesion }</TableCell>
                                            <TableCell> {item.usuNombres}</TableCell>
                                            <TableCell> {item.usuApellidos}</TableCell>
                                            <TableCell> {item.usuEmail}</TableCell>
                                            <TableCell> {item.usuTelefono}</TableCell>
                                            <TableCell> {item.rol}</TableCell>
                                            <TableCell> {Strings[item.usuEstadoDescripcion?.toLowerCase()]}</TableCell>
                                        </TableRow>
                                    )}

                                </TableBody>
                            </Table>
                        </TableContainer>
                    )
                )}
                <PlusFab component={Button} onClick={()=>history.push(history.location.pathname,{})} />
                <Crear/>
            </section>
        </>
    )


}


const mapStateToProps = (state) => ({
    agentes: state.agentes
})
export default connect(mapStateToProps, { mostrarAgentes })(Agentes);
