import React, {useEffect, useState} from 'react';
import {makeStyles, Typography, Button} from '@material-ui/core';

import Chart from "react-apexcharts";
import {formatPeso, moment} from "../../helpers";
import {connect} from "react-redux";
import {getIngresos} from "../../services";
import LoadingProgress from "../LoadingProgress";
import {CloudDownloadOutlined} from "@material-ui/icons";
import downloadFiles from "../../helpers/downloadFiles";
import {SelectSmart} from "../CustomTextField";
import Strings from "../../assets/strings";

const useStyles = makeStyles(theme => ({
    root:{
        background: theme.palette.common.white,
        border: "1px solid " +theme.palette.grey[300],
    },
    filterButton: {
        //margin: theme.spacing.unit,
        padding: "2px 5px",
        minHeight: "auto",
        minWidth: 104,
        marginLeft: 4,
        "& svg":{
            width:18,
        }
    },
    head:{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 "+theme.spacing(1)+"px",
        height: 47,
        borderBottom: "1px solid "+theme.palette.grey[300],
        marginBottom: theme.spacing(1),
        "&> p":{
            marginRight: "auto"
        },
        "& button":{
            textTransform: "capitalize",
            fontWeight: "normal",
            fontSize: ".9rem",
            background: "none !important",
            "&:after":{
                content: "''",
                borderRight: "1px solid",
                height: "58%",
                position: "absolute",
                right: -4,
                zIndex: 1,
                opacity: .4
            }
        }
    },
    yearSelect:{
        textAlign:"right",
        width:82,
        "& .MuiInput-root":{
            border: "none !important",
            outline: "none",
            margin: "0 !important",
            "&:after":{
                display: "none !important",
            }
        },
        "& .MuiSelect-select":{
            marginRight:theme.spacing(1),
            background: "none",
        }

    },
    chart:{
        "& .total":{
            position: "absolute",
            right: theme.spacing(2),
        },
        "& .apexcharts-menu":{
            width: "39px", overflow: "hidden",  minWidth: 0,
            "&>div":{
                whiteSpace: "nowrap",
                marginLeft: "-77px",
                wordSpacing: "23px"
            }
        },

    },
    tableDetail:{
        width:"100%",
        minWidth: 800,
        textAlign: "left",
        fontSize: 12,
        background: theme.palette.grey[50],
        padding: "8px 3px",
        "& tr":{
            background: "transparent !important",

        },
        "& th":{
            fontWeight: 500,
            padding: 5,
        },
        "& td":{
            padding: 5,
        }
    }
}));

const CURRENT_YEAR = new Date().getFullYear().toString();
function years() {
    var years = [];
    var year = parseInt(CURRENT_YEAR);
    for(let i=0;i<=7;i++){
        years.push(year--);
        year = year--
    }
    return years;
}
function locateSeries(datos = []){
        let converted = [];

        converted = datos.map(e=>(
            {
                name:Strings[e.name],
                data: e.data}
        ));

       return converted;
}
function IngresosChart({ingresos,inmuebleId}) {

    const classes = useStyles();
    const [loading, seLoading] = useState(false);
    const [anio, setAnio] = useState(CURRENT_YEAR);
    const [data, seData] = useState([]);

    const fetch = (value = CURRENT_YEAR) => {
        setAnio(value);
        if(value === CURRENT_YEAR && !inmuebleId) return seData(locateSeries(ingresos)); //para traer los datos ya cargados en redux
        seLoading(true);
        getIngresos(value,inmuebleId).then(res=>{
            seData(locateSeries(res.data))
        }).finally(()=>seLoading(false));
    };

    useEffect(()=>{
        fetch()
    },[ingresos])


    const optionsChart = {
        chart: {
            type: 'bar',
            height: 440,
            toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: false,
                    selection: true,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false ,
                },
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
              //  columnWidth: '65%',
                endingShape: 'flat'
            },
        },
        // grid: {
        //     borderColor: '#ccc',
        //     strokeDashArray: 7,
        //     xaxis: {
        //         lines: {
        //             show: true
        //         }
        //     }
        // },
        dataLabels: {
            enabled: false,
            formatter: function (val) {
                return formatPeso(val);
            },
           // offsetY: -20,
            style: {
              //  fontSize: '12px',
                colors: ["#304758"]
            }
        },
        stroke: {
            show: true,
            width: 1,
            colors: ['transparent']
        },
        xaxis: {
            categories: [...Array(12).keys()],
            labels: {
                formatter: (number) => moment().month(number).format("MMM"),
            }
        },
        yaxis: {
            labels: {
                formatter: (value) => value.toFixed(),
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            shared: true,
            followCursor:true,
            y: {
                formatter: (value) => formatPeso(value),
            }
        }

    };


    return (
        <div className={classes.root}>
            <div className={classes.head}>
                <Typography>{Strings.ingresos}</Typography>
                {!inmuebleId &&
                <Button variant="text" style={{display:"none"}} startIcon={<CloudDownloadOutlined/>}
                        size={"small"} onClick={()=>downloadFiles({anio},"resumenGrafico")}>
                    {Strings.exportar}
                </Button>}
                <div className={classes.yearSelect}>
                        <SelectSmart
                            value={anio}
                            onChange={({target})=>fetch(target.value)}
                            opciones={years().map((year) =>({key:year,value:year}))}
                        />
                </div>
            </div>

            <div className={classes.chart}>
                {loading && <LoadingProgress/>}
                <Chart options={optionsChart} series={data} type="bar" height={440} />
            </div>
        </div>
    )
}
const mapStateToProps = (state) => ({
    ingresos: state.dashBoard.ingresosChart || []
});
export default connect(mapStateToProps)(IngresosChart);