import React,{useEffect,useState} from 'react';
import {connect} from "react-redux";
import {mostrarAppUsers} from "../../redux/actions/appUsersActions";
import AppBar from '../../components/appBar'
import SeacrhAppBar from '../../components/SeacrhAppBar'
import Strings from '../../assets/strings'
import {FormControl, TableRow, TableCell, TableBody, makeStyles} from "@material-ui/core";
import LoadingProgress from "../../components/LoadingProgress";
import {formatDate, moment} from "../../helpers";
import {FilteredAlert, ListViewMode, MostrarError, Pagination, TableSort} from "../../components";
import {getAdminEnums} from "../../services";
import {useHistory} from 'react-router-dom'
import Crear from "./Crear"
const useStyles = makeStyles(theme => ({
    childRow:{
        background: theme.palette.grey[600] + " !important",
        display:"none",
      height: 30,
      "& td":{
        color: "white",

      } ,
      "&.show":{
            display:"table-row",
        }
    },
  root: {
    width: '100%',
    marginTop: 13,
    overflowX: 'auto',
  },
  selectEstado:{
    background: "transparent",
    border: "none",
    borderLeft: "1px solid #bdbdbd",
    padding: "3px 0px 0px 6px",
      textTransform:"capitalize",
  },
  speedDial:{
    position: 'absolute',
    '&.MuiSpeedDial-directionUp': {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
    cancel:{
        background:"rgb(255 220 209) !important",
    },
    aviso:{
        background:"rgb(255 244 236) !important",
    }
}));
let initialState={
    name: "",
    plan: "-1",
    estado: "",
    page: 1,
    pageSize: 100,
    orderBy: "usuFechaCreacion",
    orderByIsDescending:"",
};

function AppUsers(props){
    const history = useHistory();
    const classes = useStyles();
    const [adminEnums, setAdminEnums] = useState({});
    const [filter, setFilter] = useState(initialState);

    const {mostrarAppUsers,appUsers: { list, isFetching,error,filtered} } = props;
    const isEmpty = (!list.results || list.results.length === 0);

    useEffect(() => {
        mostrarAppUsers(initialState);
        getAdminEnums().then(({data})=>{
            setAdminEnums(data)
        })
    }, [mostrarAppUsers,setAdminEnums]);
    //set filtered params
    useEffect(()=>setFilter({...initialState,...filtered}),[setFilter,filtered])

    const enum2text = (prop,value) => {
        if(!prop || !value) return "";
        if(adminEnums[prop]){
            let val = adminEnums[prop].find(e=>e.key === value.toString())
            if(val) return val?.value?.toLowerCase();
        }
        return "";
    };

    const handleFilter = e => {
        const {name, value} = e.target;
        mostrarAppUsers({...filter,[name]:value})
    };
    const tableSortProps = orderBy => {
        return {
            active:filter.orderBy === orderBy,
            direction: filter.orderByIsDescending ? "desc" : 'asc',
            onClick:()=>mostrarAppUsers({...filter,orderBy,orderByIsDescending:!filter.orderByIsDescending})
        }
    };

    return (
        <>
            <AppBar title={"Usuarios"}>
                <SeacrhAppBar value={filter.name} name={"name"} onChange={handleFilter}/>
                <FormControl>
                    <select
                        value={filter.plan}
                        onChange={handleFilter}
                        name = 'plan'
                        className={classes.selectEstado}
                    >
                        <option value="">{Strings.seleccione}</option>
                        <option value="-1">{Strings.todo}</option>
                        {adminEnums["planes"] && adminEnums["planes"].map((e)=>
                            <option key={e.key} value={e.key}>{e.value}</option>
                        )}
                    </select>
                </FormControl>
            </AppBar>
            <ListViewMode pageRows={list.pageSize} pageTotalRows={list.totalRecordCount}>
                <FilteredAlert onClose={()=>mostrarAppUsers({})} filter={filtered}/>
            </ListViewMode>
            <section className="contentInner" style={isFetching?{opacity:.8}:{opacity:1}}>
                {isFetching && <LoadingProgress vh/>}
                {error && <MostrarError errors={error}/>}


                <TableSort data={[
                    {pro:"usuInicioSesion",text:"Usuario"},
                    {pro:"usuNombres",text:"Nombres"},
                    {pro:"usuEmail",text:"Email"},
                    {pro:"usuTipoPlan",text:"Plan"},
                    {pro:"usuMaxInmueble",text:"Inmuebles"},
                    {pro:"ultimoPago",text:"último pago"},
                    {pro:"usuMaxAgentes",text:"Max Agentes"},
                    {pro:"usuPais",text:"País"},
                    {pro:"usuFechaCreacion",text:"Resgistro"},
                    {pro:"usuContactado",text:Strings.comentario},
                    {pro:"usuSubscriptionId",text:"PayPal"},

                    {pro:"clientes",text:"Clientes"},
                    {pro:"usuAviso",text:"Avíso"},
                    {pro:"usuTelefono",text:"Teléfono"},
                    {pro:"usuTipo",text:"Tipo"},
                    {pro:"pagos",text:"Pagos"},
                    {pro:"usuOrderId",text:"usuOrderId"}
                ]} onSort={tableSortProps} stickyFCol={true} size={"small"} lessVh={205}>
                    <TableBody>
                    {!isEmpty && list?.results?.map(usu =>{
                        return(
                                <TableRow key={usu.usuId}
                                          className={ usu.usuEstado === 0 ? classes.cancel:(usu.usuAviso === 1 ? classes.aviso:"")}
                                          onClick={()=>!window.getSelection().toString() && history.push("/appUsers/"+usu.usuId)}
                                          onContextMenu={(e)=>{e.preventDefault();window.open("/appUsers/"+usu.usuId,"_blank")}}
                                >
                                    <TableCell>{usu.usuInicioSesion}</TableCell>
                                    <TableCell>{usu.usuNombres} {usu.usuApellidos}</TableCell>
                                    <TableCell>{usu.usuEmail}</TableCell>
                                    <TableCell>{enum2text("planes",usu.usuTipoPlan)}</TableCell>
                                    <TableCell>{usu.inmuebles} / {usu.usuMaxInmueble}</TableCell>
                                    <TableCell>{formatDate(usu.ultimoPago)}</TableCell>
                                    <TableCell>{usu.usuMaxAgentes}</TableCell>
                                    <TableCell>{usu.usuPais}</TableCell>
                                    <TableCell>{moment(usu.usuFechaCreacion).format('L - h:mm a')}</TableCell>
                                    <TableCell className="comment"><span>{usu.usuContactado}</span></TableCell>
                                    <TableCell>{usu.usuSubscriptionId}</TableCell>
                                    {/*More*/}
                                    <TableCell>{usu.clientes}</TableCell>
                                    <TableCell>{enum2text("tiposAviso",usu.usuAviso)}</TableCell>
                                    <TableCell>{usu.usuTelefono}</TableCell>
                                    <TableCell>{enum2text("tiposUsuarios",usu.usuTipo)}</TableCell>
                                    <TableCell>{usu.pagos}</TableCell>
                                    <TableCell>{usu.usuOrderId}</TableCell>

                                </TableRow>)
                    })}
                    </TableBody>
                </TableSort>
                <Pagination
                    empty={Boolean(isEmpty && !isFetching)}
                    count={list.totalRecordCount || 0}
                    page={list.pageNo -1}
                    rowsPerPage={filter.pageSize}
                    onChangePage={(e,page)=>handleFilter({target:{name:"page",value:page+1}})}
                    onChangeRowsPerPage={handleFilter}
                />
                <Crear/>

            </section>
        </>
    );
}
const mapStateToProps = (state) => ({
    appUsers: state.appUsers,
})
export default connect(mapStateToProps, {mostrarAppUsers})(AppUsers);
