import { servicioContratoTypes, _FULFILLED, _PENDING, _REJECTED } from '../actions/types';
const initialState = {
    list: [],
    isFetching: false,
    isPending: false,
    error: null,
};

export default function index(state = initialState, action={}) {

    switch (action.type) {
        case servicioContratoTypes.MOSTRAR_SERVICIOS_CONTRATO + _PENDING: {
            return {
                ...state,
                isFetching: true,
                error: {}
            }
        }
        case servicioContratoTypes.MOSTRAR_SERVICIOS_CONTRATO + _FULFILLED: {
            return {
                ...state,
                list: action.payload.data,
                isFetching: false,
            }
        }
        case servicioContratoTypes.MOSTRAR_SERVICIOS_CONTRATO + _REJECTED: {
            return {
                ...state,
                isFetching: false,
            }
        }
        //agregar  o editar

        case servicioContratoTypes.AGREGAR_EDITAR_SERVICIO_CONTRATO + _PENDING: {
            return {
                ...state,
                isPending: true,
                error: {}
            }
        }
        case servicioContratoTypes.AGREGAR_EDITAR_SERVICIO_CONTRATO + _FULFILLED: {
            const { data } = action.payload;
            var list = state.list.filter(e => e.serId !== data.serId);
            list.unshift(data);

            return {
                ...state,
                list,
                isPending: false,
                error: {}
            }
        }
        case servicioContratoTypes.AGREGAR_EDITAR_SERVICIO_CONTRATO + _REJECTED: {
            return {
                ...state,
                isPending: false,
                isFetching: false,
            }
        }
        //Eliminar

        case servicioContratoTypes.ELIMINAR_SERVICIO_CONTRATO + _FULFILLED: {
            const { data: id  } = action.payload;
            let list = state.list.filter(e => e.serId !== id);
            return {
                ...state,
                list,
                isPending: false,
                error: {}
            }
        }
        case servicioContratoTypes.ELIMINAR_SERVICIO_CONTRATO + _REJECTED: {
            return {
                ...state,
                isPending: false,
                isFetching: false,
            }
        }

        default:
            return state;
    }
}
