import React, {useState,useEffect} from 'react';
import {
    FormControl,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    Dialog,
    makeStyles,
    ListItemIcon, Checkbox, DialogActions, Button, Input, DialogContent
} from "@material-ui/core";
import { mostrarCaracteristicasSinoEsta } from '../../redux/actions/inmCaractActions';

import Strings from "../../assets/strings";
import {connect} from "react-redux";
const useStyles = makeStyles(theme => ({
    status:{
        fontSize: ".8rem",
        // display: "block",
        // width: "100%",
        // padding: "0px 8px",
        // border: 14,
        // borderLeft: "2px solid transparent",

        "&.e0":{
            color:"red",
            borderColor: "red"
        },
        "&.e1":{
            color:"#54e69d",
            borderColor: "#54e69d"
        },
        "&.e2":{
            color:"orange",
            borderColor: "orange"
        }
    },
    loading:{
        width: "13px !important",
        height: "13px !important",
        position: "absolute",
        right: 12,
        top: 15,
    },
    overClick:{
        position: "absolute", height: "100%", width: "100%",zIndex: 1, cursor: "pointer"
    }
}));


function Index({ mostrarCaracteristicasSinoEsta,caracteristicas:{list},onChange, name,value=[],cliente,required,disabled= false}) {
    const classes = useStyles();
    const [openDialog, setOpenDialog] = useState(false)


    useEffect(() => {
        mostrarCaracteristicasSinoEsta();
    }, [mostrarCaracteristicasSinoEsta]);

    const handleListItemClick = v => {
        // console.log(value)
        let list = value;
        let found = list.findIndex(l=>l.carId === v.carId);
        if(found >= 0)
            list.splice(found,1)
        else
            list.push(v)

        if(onChange)onChange(list);
    };
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    return (
        <>
            <FormControl fullWidth {...((!value) && {required})}  disabled={disabled}>
                <div className={classes.overClick} {...((!disabled) && { onClick: handleOpenDialog})}/>
                <InputLabel htmlFor="cliId" style={disabled?{}:{color:"inherit"}}>{Strings.caracteristicas}</InputLabel>
                <Input value={value.length+" items Seleccionado"}/>
            </FormControl>
            <Dialog onClose={()=>setOpenDialog(false)}  fullWidth maxWidth={"xs"}  open={openDialog}>
                <DialogContent>
                    <List>
                        {list?.map(c => (
                            <ListItem dense button onClick={() => handleListItemClick(c)} key={c.carId}>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={!!value?.find(s=>s.carId === c.carId)}
                                        tabIndex={-1}
                                        disableRipple
                                    />
                                </ListItemIcon>
                                <ListItemText primary={c.carDescripcion}/>
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button type="submit" color="primary" onClick={()=>setOpenDialog(false)} >Ok</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
const mapStateToProps = (state) => ({
    caracteristicas: state.caracteristicas,
})
export default connect(mapStateToProps, { mostrarCaracteristicasSinoEsta })(Index);


// import React, { useEffect } from 'react';
// import { connect } from 'react-redux';
// import { mostrarCaracteristicasSinoEsta } from '../../redux/actions/inmCaractActions';
//
// import Autocomplete from "@material-ui/lab/Autocomplete";
// import TextField from "@material-ui/core/TextField";
// import CircularProgress from "@material-ui/core/CircularProgress";
//
//
// function SelectInCaracteristica({value= [], onChange,caracteristicas, mostrarCaracteristicasSinoEsta,...inputProps}) {
//
//     useEffect(() => {
//         mostrarCaracteristicasSinoEsta();
//     }, [mostrarCaracteristicasSinoEsta]);
//
//
//     const options = caracteristicas?.list.map(opc=>({key:opc.carId,value:opc.carDescripcion})) || [];
//
//
//     return (
//         <Autocomplete
//             multiple
//             limitTags={3}
//             defaultValue={[]}
//             getOptionLabel={(option) => option.value}
//             options={options}
//             loading={caracteristicas.isFetching}
//             onChange={onChange}
//             size="small"
//
//             //  renderInput={(params) => <TextField {...params} {...inputProps} onChange={handleSearchChange} />}
//
//             renderInput={(params) => (
//                 <TextField
//                     label={"Característica "}
//                     {...params}
//                     {...inputProps}
//                     InputProps={{
//                         ...params.InputProps,
//                         endAdornment: (
//                             <React.Fragment>
//                                 {caracteristicas.isFetching ? <CircularProgress color="inherit" size={15} /> : null}
//                                 {params.InputProps.endAdornment}
//                             </React.Fragment>
//                         ),
//                     }}
//                 />
//             )}
//         />
//         // <FormControl fullWidth required={required} margin={margin} >
//         //     <InputLabel shrink>{label}</InputLabel>
//         //     <SelectSmart
//         //         value={value}
//         //         multiple
//         //         onChange={onChange}
//         //         name={name}
//         //         loading={caracteristicas.isFetching }
//         //         required={required}
//         //         opciones={options}
//         //     />
//         // </FormControl>
//     );
// }
//
// const mapStateToProps = (state) => ({
//     caracteristicas: state.caracteristicas,
// })
// export default connect(mapStateToProps, { mostrarCaracteristicasSinoEsta })(SelectInCaracteristica);
