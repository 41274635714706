import React, { useEffect } from 'react';
import { MostrarError } from "../../components/MostrarError";
import { connect } from 'react-redux';
import { mostrarPaises} from '../../redux/actions/paisesActions';
import AppBar from "../../components/appBar";
import {Table, TableBody, TableCell,TableHead, TableContainer, TableRow} from "@material-ui/core";
import Strings from "../../assets/strings"
import LoadingProgress from "../../components/LoadingProgress";
function  Paises(props) {
    const { mostrarPaises } = props
    useEffect(() => {
        mostrarPaises();
    }, [mostrarPaises])

    const { paises: { list, isFetching, error } } = props;
    const isEmpty = list.length === 0
    return(
        <>
            <AppBar title={Strings.paises}></AppBar>
            <section className="contentInner" style={isFetching?{opacity:.8}:{opacity:1}}>
                {isFetching && isEmpty && <LoadingProgress vh/>}
                <MostrarError errors={error}/>
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{Strings.codigo}</TableCell>
                                <TableCell>{Strings.descripcion}</TableCell>
                                <TableCell>{Strings.nacionalidad}</TableCell>
                                <TableCell>{Strings.moneda}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!isEmpty && list.map(item =>
                                <TableRow key={item.paiCodigo}>
                                    <TableCell> {item.paiCodigo}</TableCell>
                                    <TableCell> {item.paiNombre}</TableCell>
                                    <TableCell> {item.paiNacionalidad}</TableCell>
                                    <TableCell> {item.paiCodeMoneda}</TableCell>
                                </TableRow>
                            )}

                        </TableBody>
                    </Table>
                </TableContainer>
                {isEmpty && <div className="isEmpty">No se encontraron registros</div>}
            </section>
        </>
    )
}
const mapStateToProps = (state) => ({
    paises: state.paises
})
export default connect(mapStateToProps, { mostrarPaises })(Paises);