import { contratoTypes } from './types';
import {getContratos,addContrato,editContrato,delContrato,addContratoNota,delContratoNota} from '../../services';
import history from "../../helpers/history";

export function agregarEditarContrato(obj) {
    return dispatch => {
        dispatch({
            type: contratoTypes.AGREGAR_EDITAR_CONTRATO,
            payload: obj.conId ? editContrato(obj) : addContrato(obj)
        }).then(({value})=>{
            let conId = value?.data?.conId;
            history.replace("/Contrato/"+conId);
        })
    }
} 
export function mostrarContratos(obj) {
    return (dispatch,getState) => {
       // if (Object.keys(getState().contratos.list).length) return false;
        dispatch({
            type: contratoTypes.MOSTRAR_CONTRATOS,
            payload: getContratos(obj)
        })
    }
}
export function mostrarContratoById(id) {
    return dispatch => {
        dispatch({
            type: contratoTypes.MOSTRAR_CONTRATO_POR_ID,
            payload: getContratos(parseInt(id))
        })
    }
}
export function eliminarContrato(id) {
    return dispatch => {
        dispatch({
            type: contratoTypes.ELIMINAR_CONTRATO,
            payload: delContrato(id)
        })
    }
}

export function agregarNota(obj) {
    return dispatch => {
        dispatch({
            type: contratoTypes.AGREGAR_CON_NOTA,
            payload: addContratoNota(obj)
        })
    }
}
export function eliminarNota(id) {
    return dispatch => {
        dispatch({
            type: contratoTypes.ELIMINAR_CON_NOTA,
            payload: delContratoNota(id)
        })
    }
}

export function toggleModal() {
    return (dispatch) => {
        dispatch({ type: contratoTypes.TOGGLE_MODAL_CONTRATO })
    }
}
export function toggleEditModal() {
    return (dispatch) => {
        dispatch({ type: contratoTypes.TOGGLE_MODAL_EDIT_CONTRATO })
    }
}