import { grupoTypes } from './types';
import { getGrupos, addGrupo, editGrupo, delGrupo } from '../../services';
import _ from 'lodash';
import history from "../../helpers/history"

export function mostrarGrupos() {
    return dispatch => {
        dispatch({
            type: grupoTypes.MOSTRAR_RUTAS,
            payload: getGrupos()
        })
    }
}
export function mostrarGruposSinoEsta() {
    return (dispatch, getState) => {
        const grupos = getState().grupos;
        if (Object.keys(grupos.list).length || grupos.isFetching) return false;
        dispatch({
            type: grupoTypes.MOSTRAR_RUTAS,
            payload: getGrupos()
        })
    }
}
export function eliminarGrupo(id) {
    return dispatch => {
        dispatch({
            type: grupoTypes.ELIMINAR_RUTA,
            payload: delGrupo(id)
        })
    }
}
export function agregarEditarGrupo(obj) {
    let cleanObj = _.omitBy(obj, (v) => !v);
    return dispatch => {
        dispatch({
            type: grupoTypes.AGREGAR_EDITAR_RUTA,
            payload: cleanObj.gruId ? editGrupo(cleanObj) : addGrupo(cleanObj)
        }).then(history.goBack)
    }
}
