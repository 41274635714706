import {inmuebleTypes, _PENDING, _REJECTED, _FULFILLED } from '../actions/types';
 
const initialState = {
    list: {},
    byId: {},
    isFetching: false,
    isPending: false,
    error: {},
}
const index = (state = initialState, action = {}) => {
    switch (action.type) {
        case inmuebleTypes.MOSTRAR_INMUEBLES + _PENDING: {
            return {
                ...state,
                isFetching: true,
                byId: {},
                error: {}
            }
        }
        case inmuebleTypes.MOSTRAR_INMUEBLES + _FULFILLED: {
            return {
                ...state,
                list: action.payload.data,
                isFetching: false,
                error: {}
            }
        }
        case inmuebleTypes.MOSTRAR_INMUEBLES + _REJECTED: {
            const { data, status } = action.payload.response || { data:"Error no definido"};
            return {
                ...state,
                isFetching: false,
                error: { data, status}
            }
        }
        //mostrar por id
        case inmuebleTypes.MOSTRAR_INMUEBLE_POR_ID + _PENDING: {
            return {
                ...state,
                isFetching: true,
                error: {}
            }
        }
        case inmuebleTypes.MOSTRAR_INMUEBLE_POR_ID + _FULFILLED: {
            return {
                ...state,
                byId: action.payload.data,
                isFetching: false,
                error: {}
            }
        }
        case inmuebleTypes.MOSTRAR_INMUEBLE_POR_ID + _REJECTED: {
            const { data, status } = action.payload.response || { data:"Error no definido"};
            return {
                ...state,
                isFetching: false,
                error: { data, status }
            }
        }
        //agregar  o editar
        case inmuebleTypes.AGREGAR_EDITAR_INMUEBLE + _PENDING: {
            return {
                ...state,
                isPending: true,
                error: {}
            }
        }
        case inmuebleTypes.AGREGAR_EDITAR_INMUEBLE + _FULFILLED: {
            const { data } = action.payload;
            let list = state.list;
            list.results?
                list.results = list.results.filter(e => e.inmId !== data.inmId): list.results = [];
            data.new = true
            //var list = state.list.filter(e => e.carId !== data.carId)
            list.results.unshift(data)

            return {
                ...state,
              //  list,
                isPending: false,
                byId: data,
                error: {}
            }
        }
        case inmuebleTypes.AGREGAR_EDITAR_INMUEBLE + _REJECTED: {
            const { data, status } = action.payload.response || { data:"Error no definido"};
            return {
                ...state,
                isPending: false,
                isFetching: false,
                error: { data, status }
            }
        }
        //Cambiar Estado
        case inmuebleTypes.CAMBIAR_INMUEBLE_PUBLICO + _PENDING: {
            return {
                ...state,
                isPending: true,
                isFetching: false,
                error: {}
            }
        }
        case inmuebleTypes.CAMBIAR_INMUEBLE_PUBLICO + _FULFILLED: {
            const { data } = action.payload;
            let byId = {...state.byId,...data};
            return {
                ...state,
                byId,
                isPending: false,
                isFetching: false,
                error: {}
            }
        }
        case inmuebleTypes.CAMBIAR_INMUEBLE_PUBLICO + _REJECTED: {
            const { data, status } = action.payload.response || { data: "Error no definido" };
            return {
                ...state,
                isPending: false,
                isFetching: false,
                error: { data, status }
            }
        }
        //Eliminar

        case inmuebleTypes.ELIMINAR_INMUEBLE + _FULFILLED: {
            const { data: id } = action.payload;
            let list = state.list
            list.results = list.results.filter(e => e.inmId !== id)
            --list.totalRecordCount
            return {
                ...state,
                list,
                isPending: false,
                showDetailsModal: false,
                byId:{},
                error: {}
            }
        }
        
        //modal details
        case inmuebleTypes.MOSTRAR_INMUEBLE_DETALLES + _FULFILLED:  {
            const { data } = action.payload;
            return {
                ...state,
                showDetailsModal: true,
                byId: data
            }
        }
        default:
            return state;
    }
};
export default index;
