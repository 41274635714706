import { combineReducers } from 'redux';
import {reducer as notifications} from 'react-notification-system-redux';

import authReducer from './authReducer';
import usosMultiples from './usosMultiplesReducer';
import inmuebles from './InmuebleReducer';
import clientes from './clientesReducer';
import roles from './rolesReducers';
import inmCaractReducers from './inmCaractReducers';
import paises from './paisesReducers';
import estados from './estadosReducers';
import servicios from './serviciosContratoReducers';
import contratos from './contratoReducer';
import pagos from './pagosReducers';
import ajustes from './ajustesReducers';
import grupos from './gruposReducers';
import mensajes from './mensajeReducers';
import theme from './themeReducer';
import appUsers from './appUsersReducers';
import agentes from './agentesReducers';
import dashBoard from './dashBoardReducers';
import gastos from './gastosReducers';
import generales from './generalesReducers';
import enums from './enumsReducers';


export default combineReducers({
    theme,
    auth: authReducer,
    generales,
    dashBoard,
    usosMultiples,
    inmuebles,
    gastos,
    clientes,
    roles,
    paises,
    estados,
    servicios,
    contratos,
    pagos,
    ajustes,
    grupos,
    mensajes,
    caracteristicas: inmCaractReducers,
    agentes,
    appUsers,
    enums,
    notifications
});