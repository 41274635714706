import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux';
import { agregarEditarInmueble, mostrarInmuebleById } from '../../redux/actions/inmuebleActions';
import {nullToEmptyString} from '../../helpers';
import {
    Grid, TextField, Button, CircularProgress,
    makeStyles, FormControlLabel, Switch
} from '@material-ui/core';
import {AppBar,MostrarError,LoadingProgress} from "../../components";
import {TextFieldCurrency} from "../../components/CustomTextField";
import SelectInCaracteristicas from "../../components/selects/SelectInCaracteristicas";
import SelectGrupo from "../../components/selects/SelectGrupo";

import {useCustomFormInput} from "../../hooks/useCustomFormInput";


import Strings from "../../assets/strings";
import AddAdjuntos from "../../components/AddAdjuntos";
import MapSearch from "../../components/MapSearch";
import SearchCliente from "../../components/selects/searchSelects/SearchCliente";
import SelectEnum from "../../components/selects/SelectEnum";
const useStyles = makeStyles(theme => ({
    root:{
        padding:"20px",

    },
    avatar:{
        "& img":{
            maxWidth: 150
        },
        [theme.breakpoints.down('sm')]: {
            "& img":{
                borderRadius: "50%",
            }
        }

    },
    submitBtn:{
        margin: 20,
    },
}));
let initialState = {
    inmId: "",
    cliId: "",
    propietario: "",
    inmReferencia: "",
    inmDescripcion: "",
    inmPublico: false,
    inmTipo: "",
    inmTipoDescripcion: "",
    gruId: "",
    grupo: "",
    "inmPrecioAlquiler": "",
    "inmPrecioVenta": "",
    paiCodeMoneda: "",
    inmAreaTotal: "",
    inmAreaConstruida: "",
    inmHabitaciones: "",
    inmBanos: "",
    inmEstacionamientos: "",
    inmAnoConstruccion: "",
    inmFechaCreacion: "",
    usuId: "",
    creadoPor: "",
    urlImage: "",
    inmGrupo:"",
    inmCoordenadas: "",
    inmDireccion: "",
    inmDireccion2: "",
    inmCampo1: "",
    inmCampo2: "",
    inmCampo3: "",
    inmCampo4: "",
    adjuntos:[],
    caracteristicas:[],
}
function CrearInmueble(props){
    const classes = useStyles();
    const customFormInput = useCustomFormInput("inm");

    let { inmId:urlInmId} = useParams()
    const {agregarEditarInmueble,mostrarInmuebleById, inmueble, isFetching,isPending, error} = props;

    const [state, setState] = useState(initialState);

    useEffect(()=>{
        if(urlInmId) mostrarInmuebleById(urlInmId);
    },[urlInmId,mostrarInmuebleById])
    useEffect(()=>{
        if(inmueble?.inmId === parseInt(urlInmId)) {
            setState({...initialState, ...nullToEmptyString(inmueble)});
        }else{
            setState(initialState)
        }
    },[urlInmId,inmueble])
    const handleChange = (e)=>{
        const {name,value,type,checked} = e.target
        if(type === "checkbox"){
            return setState({...state,[name]: checked});
        }
        return setState({...state,[name]: value});
    }
    const handleMapChange = (obj)=>{
        let dir = {};
        if(obj.direccion) dir.inmDireccion = obj.direccion;
        if(obj.coordenadas) dir.inmCoordenadas = obj.coordenadas;

        return setState({...state,...dir});
    }
    const submit = (e) =>{
        e.preventDefault();
        let obj = {...state}
        agregarEditarInmueble(obj);
    }

    return(
        <>
            <AppBar title={state.inmId ? Strings.editar+" Inmueble": Strings.crear+" Inmueble"} >
                <Button color="inherit" disabled={isPending} type="submit" form="form1" value="Submit">
                    {isPending? (
                        <span><CircularProgress size={14} color="inherit" /> &nbsp; {Strings.cargando}</span>
                    ):(
                        <span>{Strings.guardar}</span>
                    )}
                </Button>
            </AppBar>
            <div className={"contentInner " + classes.root}>
                <MostrarError errors={error}/>
                {(isFetching || isPending) && <LoadingProgress vh/>}

                <form id="form1" onSubmit={submit} autoComplete={"off"}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                name={"inmDescripcion"}
                                label={Strings.descripcion}
                                value={state.inmDescripcion}
                                onChange={handleChange}
                                inputProps={{maxLength: 300}}
                                required
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TextField
                                name={"inmReferencia"}
                                label={Strings.referencia}
                                value={state.inmReferencia}
                                onChange={handleChange}
                                inputProps={{maxLength: 20}}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <SelectEnum group={"tiposInmueble"}
                                        name={"inmTipo"}
                                        label={Strings.tipo}
                                        value={state.inmTipo}
                                        onChange={handleChange}
                                        required
                            />
                        </Grid>
                        <Grid item xs={6} sm={3} >
                            <TextField
                                name="inmAnoConstruccion"
                                label={Strings.anio+" Construcción"}
                                value={state.inmAnoConstruccion}
                                onChange={handleChange}
                                type={"number"}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6} sm={3} >
                            <TextField
                                name="inmAreaTotal"
                                label="Area Total"
                                value={state.inmAreaTotal}
                                onChange={handleChange}
                                type={"number"}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6} sm={3} >
                            <TextField
                                name="inmAreaConstruida"
                                label="Area Construida"
                                value={state.inmAreaConstruida}
                                onChange={handleChange}
                                type={"number"}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6} sm={3} >
                            <TextField
                                name="inmHabitaciones"
                                label="Habitaciones"
                                value={state.inmHabitaciones}
                                onChange={handleChange}
                                type={"number"}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6} sm={3} >
                            <TextField
                                name="inmBanos"
                                label="Baños"
                                value={state.inmBanos}
                                onChange={handleChange}
                                type={"number"}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6} sm={3} >
                            <TextField
                                name="inmEstacionamientos"
                                label="Estacionamientos"
                                value={state.inmEstacionamientos}
                                onChange={handleChange}
                                type={"number"}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} >
                            <SearchCliente
                                filter={{tipo: 2}}
                                value={state.propietario}
                                label={"Propietario"}
                                onChange={(v)=>{
                                    setState(prev => ({...prev,cliId:v.cliId,propietario:v.cliente}))
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <SelectInCaracteristicas
                               value={state.caracteristicas}
                               onChange={(v=>setState({...state,caracteristicas:v}))}
                            />
                        </Grid>
                        {customFormInput && Object.keys(customFormInput).map((key)=>
                            <Grid item xs={12} sm={6} key={key}>
                                <TextField
                                    name={key}
                                    label={customFormInput[key].name}
                                    required={customFormInput[key].isRequired}
                                    value={state[key] ? state[key] : ""}
                                    onChange={handleChange}
                                    inputProps={{maxLength: 50}}
                                    fullWidth
                                />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Switch checked={state.inmPublico} onChange={handleChange} name="inmPublico" />}
                                label="Público"
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TextFieldCurrency
                                name="inmPrecioAlquiler"
                                label={Strings.precio+" "+Strings.alquiler}
                                value={state.inmPrecioAlquiler}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TextFieldCurrency
                                name="inmPrecioVenta"
                                label={Strings.precio+" "+Strings.venta}
                                value={state.inmPrecioVenta}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <SelectGrupo
                                value={state.gruId}
                                grupo={state.grupo}
                                label={Strings.grupo}
                                onChange={v => setState({...state,...v})}
                            />
                        </Grid>
                        {state.gruId ?(
                            <Grid item xs={12}>
                                <TextField
                                    value={state.inmDireccion+(state.inmDireccion2 && ", "+state.inmDireccion2)}
                                    disabled
                                    fullWidth
                                />
                            </Grid>
                        ):(
                            <>
                                <Grid item xs={12} sm={9} md={7}>
                                    <MapSearch handleChange={handleMapChange}
                                               direccion={state.inmDireccion} coordenadas={state.inmCoordenadas}/>
                                </Grid>
                                <Grid item xs={12}  sm={5} md={5}>
                                    <TextField
                                        name="inmDireccion2"
                                        label={Strings.direccion + " 2"}
                                        value={state.inmDireccion2}
                                        placeholder={Strings.referenciaDireccion}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 100}}
                                        fullWidth
                                    />
                                </Grid>
                            </>
                        )}

                    </Grid>
                    <br/>

                    <AddAdjuntos adjuntos={state.adjuntos}
                                 onChange={(adjuntos)=>setState({...state,adjuntos})} />

                </form>
                <br/>
            </div>
        </>
    );

}

const mapStateToProps = (state) => ({
    inmueble: state.inmuebles.byId,
    isPending: state.inmuebles.isPending,
    isFetching: state.inmuebles.isFetching,
    error: state.inmuebles.error
})
export default connect(mapStateToProps, { agregarEditarInmueble, mostrarInmuebleById })(CrearInmueble);
