import React from 'react';
import Strings from "../assets/strings";
import {FilterList} from "@material-ui/icons";
import {Chip, makeStyles} from "@material-ui/core";
const useStyles = makeStyles(theme => ({
    filter:{
        padding:" 0 8px",
        height: 28,
        borderRadius: 3,
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(1),
        },
        "& span":{
            marginRight: theme.spacing(2),
        }
    }
}));

export function isFiltered(filter = []){
    const skipProps =["orderBy", "orderByIsDescending","pageSize","page"];

    const hasOtherProps = Object.keys(filter || {}).some(r=> !skipProps.includes(r))
    return !!hasOtherProps;
}


export default function FilteredAlert({onClose,filter}) {
    const classes = useStyles();
    if(!isFiltered(filter)) return false;
    return (
        <Chip
            className={classes.filter}
            size={"small"}
            icon={<FilterList />}
            label={Strings.filtrado}
            onDelete={onClose}
            onClick={onClose}
            color="secondary"
            variant="outlined"
        />
    );
}
